import React, { useContext, useState } from "react";
import {
    FaAngleRight,
    FaAngleDown,
    FaRegFolder,
    FaRegFolderOpen,
    FaGlobe, FaVideo
} from "react-icons/fa";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import Videoplayer from "../VideoPlayer/Videoplayer";
import './NestedList.css'
import { userContext } from "../../hooks/UseContextProvider";

const leaf = { paddingLeft: "70px" };
const branch = { paddingLeft: "40px" };


const Leaf = ({ url, label, onLeafClick }, idx) => {

    const { currentUser } = useContext(userContext);
    const dynamicPath = `/bdsimulLab01/${currentUser._id}/mycourse/${label}`;
    const [lastTitle, setLastTitle] = useState("")

    const handleLinkClick = () => {
        setLastTitle(null)
        onLeafClick(url);
        setLastTitle(label)

        console.log('This is Label in LinkClick', label)
    };

    return (
        <div>
            <Container >
                <Link
                    to={dynamicPath}
                    className="list-group-item"
                    style={leaf}
                    key={`leaf-${idx}`}
                    onClick={handleLinkClick}
                >
                    <FaVideo /> {label}
                </Link>


            </Container>
        </div>
    );
};

const Branch = ({ label, children, onLeafClick }, idx) => {
    const [open, setOpen] = useState(true);

    const { currentUser } = useContext(userContext);

    const childList = children.map((leaf, idx) => (
        <Leaf key={`leaf-${idx}`} {...leaf} onLeafClick={onLeafClick} />
    ));
    const dynamicPath = `/bdsimulLab01/${currentUser._id}/mycourse`;

    return (
        <>
            <Link
                to={dynamicPath}
                className="list-group-item Module-title"
                style={branch}
                onClick={() => setOpen(!open)}
                key={`branch-${idx}`}
            >
                {open ? <FaAngleDown /> : <FaAngleRight />}{" "}
                {open ? <FaRegFolderOpen /> : <FaRegFolder />} {label}
            </Link>
            {open && childList}
        </>
    );
};

const Root = ({ label, children, onLeafClick }, idx) => {
    const [open, setOpen] = useState(true);
    const { currentUser } = useContext(userContext);
    const dynamicPath = `/bdsimulLab01/${currentUser._id}/mycourse`;
    const childList = children.map((branch, idx) => (
        <Branch key={`branch-${idx}`} {...branch} onLeafClick={onLeafClick} />
    ));

    return (
        < >
            <Link
                to={dynamicPath}
                className="list-group-item main-modules"
                onClick={() => setOpen(!open)}
                key={`root-${idx}`}
            >
                {open ? <FaAngleDown /> : <FaAngleRight />}{" "}
                {open ? <FaRegFolderOpen /> : <FaRegFolder />} {label}
            </Link>
            {open && childList}
        </>
    );
};

export default function NestedListGroup({ playlist }) {
    const [url, setUrl] = useState(
        "https://www.canva.com/design/DAF5wlShtTs/watch?embed"
    );
    const [Lastlabel, setLabel] = useState("")


    const handleLeafClick = (leafUrl, leafLabel) => {
        setUrl(leafUrl);
        setLabel(leafLabel)

        console.log('This is leaf', leafUrl)
        console.log('This is leaf label', leafLabel)

        // console.log('This is leaf url', url)
    };

    return (
        <Container >
            <Row>
                <Col lg={7} className="videoplayers mb-5">
                    <Videoplayer url={url}></Videoplayer>
                    <p style={{ color: 'white' }}>Last played title: {Lastlabel}</p>

                </Col>
                <Col lg={4} className="videoplay-list">
                    <div className="list-group">
                        {playlist.map((root, idx) => (
                            <Root
                                key={`root-${idx}`}
                                {...root}
                                onLeafClick={handleLeafClick}

                            />
                        ))}
                    </div>

                </Col>
            </Row>
        </Container>
    );
}
