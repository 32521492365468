import axios from 'axios';
import React, { useState, useEffect } from 'react';


const EmailSender = () => {
    const [data, setData] = useState([]);

    useEffect(() => {
        // Fetch data from the given URL
        axios.get('https://bd-SIMULAB.onrender.com/enrollrequestdb')
            .then(response => {
                setData(response.data);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, []);

    const sendEmail = async (email, name) => {

        const meetingLink = 'https://meet.google.com/vmm-bhgw-zri'
        const facebookGroup = 'https://fb.me/g/p_5xE7iMy3KJ3RrNAS/k8YC8rPU'
        const emailData = {
            to: email,
            subject: 'Welcome to BD-SimuLab - Important Details Inside!',
            message: `Dear ${name}, \n We hope this email finds you well and filled with enthusiasm for the upcoming learning journey with BD-SimuLab! We are thrilled to welcome you to the course titled 'Simulation for Industrial Application: Fluid and Heat Transfer Analysis.'\n To kick off this exciting venture, we invite you to join us for an online orientation meeting on 6th January 2024, Saturday, at 9:00 pm. During this session, we will guide you through the process of accessing our online portal and course materials.\n\n Meeting Link : ${meetingLink} \n Date: 6th January 2024, Saturday \nTime:9:00 PM (BD Time) \n\nAdditionally, we have created a Private Facebook group exclusively for the students of the first batch. This group will serve as a platform for discussions, Q&A sessions, and a sense of community among participants. \n You can join the group by clicking ${facebookGroup} \n\n As we embark on this educational journey together, we also take this opportunity to wish you a Happy New Year 2024! May it be filled with success, growth, and new achievements. \n Attention!  Please do not make any payments to anyone at this time. The official announcement regarding the course fee of 1000 Taka will be communicated to you later through email or our official group. We will not be responsible for any payments made outside of our official communication channels.\n If you have any immediate questions or concerns, feel free to reach out to us. \n\n Regards,\nTeam BD-SimuLab `,
        };

        try {
            // Send email using the server endpoint
            const emailResponse = await axios.post('https://bd-SIMULAB.onrender.com/send-email', emailData);
            console.log('Email sent successfully:', emailResponse.data);
        } catch (error) {
            console.error('Error sending email:', error);
        }
    };

    const sendEmails = () => {

        // Loop through the data and send emails
        data.forEach(entry => {
            const { Email, FullName } = entry;

            sendEmail(Email, FullName);
            console.log(Email, FullName)

        });
    };

    return (
        <div>
            <button>Send Emails</button>
        </div>
    );
};

export default EmailSender;
