import React, { createContext, useEffect, useState } from 'react';
import useAuth from './useAuth';
import useFirebase from './useFirebase';
import { Link } from 'react-router-dom';
import { FaGlobe } from 'react-icons/fa';
// import useFirebase from './useFirebase';





export const userContext = createContext();


const UseContextProvider = ({ children }) => {
    // const firebaseContext = useFirebase();
    // const [doctors, setDoctors] = useState([]);
    const [services, setServices] = useState([]);
    const [playlist, setPlaylist] = useState([]);
    const [usersProfile, setUsersProfile] = useState([]);
    const [usersData, setUserData] = useState([]);

    const [currentUser, setCurrentUser] = useState([]);

    const [videoUrl, setVideoUrl] = useState('');

    const firebaseData = useFirebase();
    const FirebaseuserEmail = firebaseData.user.email;

    const auth = useAuth();


    useEffect(() => {
        fetch('./PlaylistB1.json')
            .then(res => res.json())
            .then(data => {
                console.log('Here is Playlist', data)
                setPlaylist(data)

            })


    }, [FirebaseuserEmail, currentUser]);
    useEffect(() => {

        setCurrentUser('');
        // console.log('Here is firebase data', FirebaseuserEmail)
        fetch('https://bd-SIMULAB.onrender.com/userprofiledb')
            .then(res => res.json())
            .then(data => {
                // console.log('Here is Userprofile Database', data)
                setUsersProfile(data);

                data.map((user) => {
                    // console.log('This are users', user.Email);
                    // console.log('This are users', user);
                    // console.log('This are users email', user.data.Email, FirebaseuserEmail);
                    if (user.Email === FirebaseuserEmail) {
                        setCurrentUser(user)

                        console.log('This is current user in user Context', user)
                    }

                })

            })

        // if (!FirebaseuserEmail) {
        //     setCurrentUser('')

        //     console.log('User logged out')
        // }
    }, [FirebaseuserEmail, auth]);

    useEffect(() => {
        setUserData(firebaseData.user)
        // console.log('This is Fire base data from use effect', firebaseData.user)

    }, [usersProfile, firebaseData])



    useEffect(() => {
        if (!usersData.email) {
            setCurrentUser('');
        }

    }
        , [usersProfile, usersData.email])





    const leaf = { paddingLeft: "70px" };


    const Leaf = ({ url, label }, idx) => {


        const URLNew = videoUrl;

        // console.log('URL in LEAF', URLNew)

        const handleLinkClick = () => {
            setVideoUrl(url);
            // console.log('This is url', url);
        };

        return (
            <div>
                <userContext.Provider value={{ videoUrl }}>
                    <Link
                        to="/mecourse"
                        className="list-group-item"
                        style={leaf}
                        key={`leaf-${idx}`}
                        onClick={handleLinkClick}
                    >
                        <FaGlobe /> {label}
                    </Link>
                </userContext.Provider>
            </div>
        );
    };






    return (
        <userContext.Provider value={{ firebaseData, playlist, usersProfile, usersData, currentUser }}>
            {children}
        </userContext.Provider>
    );
};

export default UseContextProvider;







