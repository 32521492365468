import React, { useContext, useEffect, useState } from 'react';
import { createUserWithEmailAndPassword, getAuth, onAuthStateChanged, sendEmailVerification, sendPasswordResetEmail } from 'firebase/auth';
import { Form, Button, Container, Row, Col, Alert } from 'react-bootstrap';
import './SignUp.css';
import { userContext } from '../hooks/UseContextProvider';

const SignUp = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [error, setError] = useState(null);
    const [emailVerificationSent, setEmailVerificationSent] = useState(false);

    const [user, setUser] = useState(null);
    const [token, setToken] = useState(null);

    const [userEmail, setUserEmail] = useState('');
    const [verifyUserEmail, setVerifyUser] = useState(false);

    const { firebaseData } = useContext(userContext);


    const [name, setName] = useState('');
    const [street, setStreet] = useState('');
    const [city, setCity] = useState('');
    const [country, setCountry] = useState('');
    const [profession, setProfession] = useState('');
    const [degree, setDegree] = useState('');
    const [phone, setPhone] = useState('');
    const [intro, setIntro] = useState('');
    const [skills, setSkills] = useState('');
    const [Interest, setInterest] = useState('');

    const [university, setUniversity] = useState("");


    useEffect(() => {
        setUserEmail(firebaseData.user.email);
        setVerifyUser(firebaseData.user.emailVerified);



        console.log('This Email is Verified', verifyUserEmail)

        const Email = email;

        // console.log('This is users data email', myEmail)
        // const address = { street, city, country }
        const myNewProfile = { name, Email, phone, street, city, country, degree, university, profession, skills, intro }

        console.log('This is new Profile from new form', myNewProfile)
    }, [firebaseData]);

    const auth = getAuth();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            setUser(user);
        });

        return unsubscribe;
    }, [auth]);

    useEffect(() => {
        if (user) {
            user
                .getIdToken()
                .then((token) => setToken(token))
                .catch((error) => console.log(error));
        }
    }, [user]);

    const handleSignup = async (e) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            setError('Passwords do not match.');
            return;
        }
        if (!/(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()_+=[\]{}|\\;:'",.<>/?-]).{8,}/.test(password)) {
            setError('Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character.');
            return;
        }
        try {
            const { user: newUser } = await createUserWithEmailAndPassword(auth, email, password);
            await sendEmailVerification(newUser);
            setEmailVerificationSent(true);


        } catch (error) {
            setError(error.message);
        }

        if (verifyUserEmail === true) {
            alert('Registration Successful!')


        }
        else if (verifyUserEmail !== true) {
            alert('A verification email sent to your email. Please verify your email first to complete signUp.')
        };

        e.target.reset();

    };

    const handlePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    // const handlePasswordReset = async (e) => {
    //     e.preventDefault();
    //     try {
    //         await sendPasswordResetEmail(auth, email);
    //         setError('Password reset email sent.');
    //     } catch (error) {
    //         setError(error.message);
    //     }

    // };


    const checkVerification = () => {

        window.location.reload();

    }



    const handleSubmit = (event) => {

        // console.log('This Is from Handle Submit', name, usersData.email, usersData)

        const Email = email;

        // console.log('This is users data email', myEmail)
        // const address = { street, city, country }
        const myNewProfile = { name, Email, phone, street, city, country, degree, university, profession, skills, intro }

        console.log('This is new Profile from new form', myNewProfile)
        fetch('https://bd-simulab.onrender.com/userprofiledb', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(myNewProfile)

        })
            .then(response => response.json())
            .then(data => {
                console.log('Response Received', data)


                if (data.insertedId) {
                    alert('Profile Successfully Created')

                    // setUserProfile(data)
                    console.log('Submitted', myNewProfile)
                    // console.log(userProfile)

                    // setIsButtonEnabled(true);

                    // event.target.reset();
                }


            })
            .catch(error => console.error(error));




        // .catch(error => {
        //     console.error(error);

        // });
        event.preventDefault();
        event.target.reset();
    };







    return (
        <Container fluid className="signUp-form">
            <Row className="justify-content-center">
                <Col lg={3} md={3} className="pe-5 mt-3">
                    <div className="SignUp-title">
                        <h2>Invest For Your</h2>
                        <h1 className="ms-5">Future.</h1>
                        <p>Shape your idea here!</p>
                        <p>www.bdsimulab.com</p>
                    </div>
                </Col>
                <Col lg={3} md={3} className="signUp">
                    <h3 className="text-center my-4">Signup Please</h3>
                    <Form onSubmit={handleSignup}>
                        {/* <Form.Group controlId="email">
                            <Form.Label>Full Name</Form.Label>
                            <Form.Control
                                type="text"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </Form.Group> */}
                        <Form.Group controlId="email">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group controlId="password">
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                type={passwordVisible ? 'text' : 'password'}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <Form.Text className="text-muted">
                                <Button
                                    variant="outline-secondary"
                                    size="sm"
                                    onClick={handlePasswordVisibility}
                                >
                                    {passwordVisible ? 'Hide' : 'Show'}
                                </Button>
                            </Form.Text>
                        </Form.Group>

                        <Form.Group controlId="confirmPassword">
                            <Form.Label>Confirm Password</Form.Label>
                            <Form.Control
                                type={passwordVisible ? 'text' : 'password'}
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                            />
                        </Form.Group>


                        {verifyUserEmail === true ? (
                            <Container>
                                <p style={{ color: 'red' }}>You are already signed in</p>
                            </Container>
                        ) : (
                            <Button variant="outline-warning" className="m-3" type="submit" block>
                                SignUp Here
                            </Button>
                        )}
                    </Form>
                    <div className="my-3">
                        {error && <Alert variant="danger">{error}</Alert>}
                        {verifyUserEmail === true &&

                            <Alert variant="success"><p>Congratulation! your Email, {user.email} has been verified successfully</p>


                            </Alert>








                        }
                        {/* {verifyUserEmail === true && <Alert variant="success">Email has been verified</Alert>} */}
                        {(verifyUserEmail !== true && userEmail) && <Alert variant="danger">You did ot verify your Email</Alert>}
                        {emailVerificationSent && (
                            <Alert variant="warning">
                                Verification email sent. Please check your email to verify.
                                <Button onClick={checkVerification}>Check Verification</Button>
                            </Alert>
                        )}

                    </div>
                </Col>
                <Col lg={3} className="ms-4">
                </Col>
            </Row>
        </Container>
    );
};

export default SignUp;
