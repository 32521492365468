import { useContext, useEffect, useState } from 'react';
import { Outlet, Navigate, useLocation, Link } from 'react-router-dom';
import { Button, Spinner } from 'react-bootstrap';
import useAuth from '../../../hooks/useAuth';
import useFirebase from '../../../hooks/useFirebase';

const PrivateRouteFB = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [user, setUser] = useState({});
    const [userID, setUserID] = useState('');
    const [loading, setLoading] = useState(true);
    const firebaseData = useFirebase();
    const firebase = useAuth();
    const location = useLocation();
    const firebaseUserEmail = firebaseData.user?.email;

    const { pathname } = location;

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch data from enrollrequestdb
                const enrollResponse = await fetch('https://bd-SIMULAB.onrender.com/enrollrequestdb');
                const enrollData = await enrollResponse.json();

                // Fetch data from campusambassadors
                const campusAmbassadorResponse = await fetch('https://bd-SIMULAB.onrender.com/campusambassadors');
                const campusAmbassadorData = await campusAmbassadorResponse.json();

                // Check if the user's email exists in either enrollrequestdb or campusambassadors
                const isEnrolled = enrollData.some((user) => user.Email === firebaseUserEmail);
                const isCampusAmbassador = campusAmbassadorData.some((user) => user.Email === firebaseUserEmail);

                // If the user is enrolled in either database, authenticate the user
                if (isEnrolled || isCampusAmbassador) {
                    const user = isEnrolled
                        ? enrollData.find((user) => user.Email === firebaseUserEmail)
                        : campusAmbassadorData.find((user) => user.Email === firebaseUserEmail);

                    setIsAuthenticated(true);
                    setUser(user);
                    setUserID(user._id);
                } else {
                    // If the user is not found in either database, set isAuthenticated to false
                    setIsAuthenticated(false);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                // Set loading to false once data is fetched (whether successful or not)
                setLoading(false);
            }
        };

        fetchData();
    }, [firebaseUserEmail]);


    // Render the spinner while loading
    if (loading) {
        return <Spinner animation="border" variant="primary" />;
    }

    // Render the Outlet if userID is available, otherwise redirect to home
    return userID ? <Outlet /> : <Link to="/home"><Button variant="outline-danger">Unauthorize Access</Button></Link>;
};

export default PrivateRouteFB;
