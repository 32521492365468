import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

function CountdownTimer() {
    const calculateTimeLeft = () => {
        const difference = +new Date('2023-12-31') - +new Date();
        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60),
            };
        }

        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearTimeout(timer);
    });

    const timerComponents = [];

    Object.keys(timeLeft).forEach((interval) => {
        if (!timeLeft[interval]) {
            return;
        }

        timerComponents.push(
            <Col key={interval} className="countdown-item">
                <span>{timeLeft[interval]}</span>
                <div>{interval}</div>
            </Col>
        );
    });

    return (
        <Container className="countdown-timer" style={{ color: 'white' }}>
            <Row>{timerComponents} Left to Enroll</Row>
        </Container>
    );
}

export default CountdownTimer;
