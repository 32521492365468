import axios from "axios";
import { Button, Col, Row } from "react-bootstrap";
import { useContext } from "react";
import { useState } from "react";
import { Container, Form } from "react-bootstrap";
import { userContext } from "../../hooks/UseContextProvider";

import './Enroll.css';

// ... (previous code)
const CourseEnroll = () => {


    const { usersProfile, usersData, currentUser } = useContext(userContext);
    const courseTitle = 'Simulation for Industrial Application: Fluid and Thermal Analysis'

    console.log('This is current user in Enroll form', currentUser);
    const { name, Email, phone } = currentUser;

    const handleSubmit = (e) => {
        e.preventDefault();


        const courseCode = '23010013'
        const studentData = { courseCode, courseTitle, name, Email, phone };
        axios.post('http://localhost:5000/enrolldb', studentData)
            .then((response) => {
                console.log(response.data);
                alert('Enrollment successful!');
            })
            .catch((error) => {
                console.error(error);
                alert('Enrollment failed. Please check your connection and try again');
            });
        console.log('This is enrolled student', studentData);
    };

    return (
        <Container fluid className="course-regContainer">
            <Col lg={3} md={6} className="course-reg">
                <Row>

                    <Form onSubmit={handleSubmit} className="course-form">
                        <Form.Group controlId="courseTitle">
                            <Form.Label>Course Title</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter course title"
                                value={courseTitle}
                                readOnly

                            />
                        </Form.Group>

                        <Form.Group controlId="name">
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter your name"
                                value={name}
                                readOnly

                            />
                        </Form.Group>

                        <Form.Group controlId="email">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="Enter email"
                                value={Email}

                                readOnly
                            />
                        </Form.Group>

                        <Form.Group controlId="phone">
                            <Form.Label>Phone</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter your phone number"
                                value={phone}
                                readOnly
                            />
                        </Form.Group>

                        <Button variant="outline-success" type="submit" className="m-4">
                            Enroll Now
                        </Button>

                    </Form>


                </Row>
            </Col>
        </Container>
    );
};
// ... (rest of the code)
export default CourseEnroll;