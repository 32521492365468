import React, { useState } from 'react';
import { Form, Button, Alert, Container, Col } from 'react-bootstrap';

import { getAuth, sendPasswordResetEmail } from 'firebase/auth';

import './Resetpassword.css';



const ResetPassword = () => {
    const [email, setEmail] = useState('');
    const [isResetSent, setIsResetSent] = useState(false);
    const [error, setError] = useState('');
    const auth = getAuth();


    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handleResetPassword = () => {

        sendPasswordResetEmail(auth, email)
            .then(() => {
                setIsResetSent(true);
                setError('');
            })
            .catch((error) => {
                setIsResetSent(false);
                setError(error.message);
            });
    };

    return (
        <div>

            {isResetSent ? (
                <Alert variant="success">Reset link sent to your email. Please check your inbox.</Alert>
            ) : (
                <Container className="pw-reset">
                    <Col lg={3}>
                        <h4>Reset Password</h4>


                        <Form>
                            <Form.Group controlId="email">
                                <Form.Label >Email</Form.Label>
                                <Form.Control
                                    type="email"

                                    placeholder="Enter your email"
                                    value={email}
                                    onChange={handleEmailChange}
                                />
                            </Form.Group>
                            <Button variant="outline-success" className="m-3" onClick={handleResetPassword}>
                                Reset Password
                            </Button>
                            {error && <Alert variant="danger">{error}</Alert>}
                        </Form>

                        <p variant="success">A paasword reset email with link will be sent to your email address. Please reset you password from the link </p>
                    </Col>

                </Container>
            )}
        </div>
    );
};

export default ResetPassword;
