// Your React component

import React, { useRef, useState } from 'react';
import axios from 'axios';

export const ContactUs = () => {
    const form = useRef();

    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const sendEmail = async (e) => {
        e.preventDefault();

        const emailData = {
            to: email, // Replace with the recipient's email address
            subject: 'Enrollment Request Confirmation',
            message: `Name: ${fullName}\nEmail: ${email}\nMessage: ${message}`,
        };

        try {
            const response = await axios.post('http://localhost:5000/send-email', emailData);
            if (response.data.success) {
                // Handle success, maybe show a success message to the user
            } else {
                // Handle failure, show an error message to the user
            }
        } catch (error) {
            console.error('Error sending email:', error);
            // Handle error, show an error message to the user
        }
    };

    return (
        <form ref={form} onSubmit={sendEmail}>
            <label htmlFor="fullName">Full Name:</label>
            <input
                type="text"
                id="fullName"
                name="fullName"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                required
            />

            <label htmlFor="email">Email:</label>
            <input
                type="email"
                id="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
            />

            <label htmlFor="message">Message:</label>
            <textarea
                id="message"
                name="message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                required
            ></textarea>

            <input type="submit" value="Send" />
        </form>
    );
};


