import React, { useContext, useEffect, useState } from "react";
import { Form, Button, Container, Row, Col, Image } from "react-bootstrap";
import axios from "axios";
import './UserProfile.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { Link } from "react-router-dom";
import { userContext } from "../hooks/UseContextProvider";

import profileimg1 from '../../Utilities/Images/userProfile1.png';
import profileheader from '../../Utilities/Images/profileHeader.png';




const UserProfile = () => {

    const [name, setName] = useState("");

    const [address, setAddress] = useState("");
    const [dob, setDob] = useState("");
    const [university, setUniversity] = useState("");
    const [profession, setProfession] = useState("");
    // let [email, setEmail] = useState("");
    const [profilePicture, setProfilePicture] = useState(null);
    const [userProfile, setUserProfile] = useState("");
    const [currentUserId, setCurrentUserId] = useState("");
    const [currenNewtUser, setCurrentUser] = useState("");
    const [profileStatus, setprofileStatus] = useState("");
    const [Myemail, setMyEmail] = useState("");


    const [street, setStreet] = useState("");
    const [city, setCity] = useState("");
    const [country, setCountry] = useState("");
    const [phone, setPhone] = useState("");
    const [countryCode, setCountryCode] = useState("+1");


    const [isButtonEnabled, setIsButtonEnabled] = useState(false);

    const [cartchErr, setErr] = useState("");




    // const auth = getAuth();
    // console.log('This is user Profile', userProfile)
    const { usersProfile, usersData, currentUser } = useContext(userContext);

    // console.log('User email Found', usersData.email)
    // findCurrenntUser();


    const handleNameChange = (event) => {
        setName(event.target.value);
        // console.log('This is Name', name)
    };
    // const handleEmailChange = (event) => {
    //     // email = event.target.value;
    //     // setEmail(email)
    //     console.log('This is email', email)

    // };

    const handleAddressChange = (event) => {
        setAddress(event.target.value);

        // console.log('This is Address', address);
    };

    const handleDobChange = (event) => {
        setDob(event.target.value);
    };

    const handleUniversityChange = (event) => {
        setUniversity(event.target.value);

        // console.log('This is University', university)
    };

    const handleProfessionChange = (event) => {
        setProfession(event.target.value);


    };

    const handlePictureChange = (event) => {
        setProfilePicture(event.target.files[0]);
    };

    const handleUpdateuser = () => {

    }

    useEffect(() => {
        usersProfile.map((user) => {

            if (usersData.email === user.email) {
                // console.log('I found the user', user)
                setCurrentUserId(user._id);
                setUserProfile(user)
                setIsButtonEnabled(true);
                console.log(user)
            }
            else {
                console.log('Sorry no user found')
            }

        })

    }, [usersProfile, Myemail, usersData.email])

    useEffect(() => {

        if (!currentUserId) {
            setprofileStatus('You need to write only a few more personal information to complete your profile. Please Complete your profile');
            console.log('This is profile status', profileStatus)
        }


    }, [usersData.email, currentUser])

    const handleSubmit = (event) => {

        // console.log('This Is from Handle Submit', name, usersData.email, usersData)

        const myEmail = usersData.email;

        // console.log('This is users data email', myEmail)
        const address = { street, city, country }
        const myNewProfile = { name, myEmail, address, university, profession, }

        // console.log('This is new Profile', myNewProfile)


        fetch('http://localhost:5000/userprofiledb', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ data: myNewProfile })
        })
            .then(response => response.json())
            .then(data => {
                console.log('Response Received', data)


                if (data.insertedId) {
                    alert('Profile Successfully Created')

                    setUserProfile(data)
                    console.log('Submitted', myNewProfile)
                    // console.log(userProfile)

                    setIsButtonEnabled(true);

                    // event.target.reset();
                }


            })
            .catch(error => console.error(error));

        // axios.post("http://localhost:5000/userprofiledb", myNewProfile)
        // console.log('This is new Profile2', myNewProfile)
        //     .then((response) => {
        //         // console.log('This is server Response', response);

        //         if (response.data.insertedId) {
        //             alert('Profile Successfully Created')

        //             setUserProfile(response.data)
        //             console.log('Submitted', myNewProfile)
        //             // console.log(userProfile)
        //             setCurrentUser(myNewProfile);
        //             setIsButtonEnabled(true);

        //             // event.target.reset();
        //         }


        //     });

        setCurrentUser(myNewProfile);

        event.target.reset();
        // .catch(error => {
        //     console.error(error);

        // });
        event.preventDefault();
    };


    const countryCodes = [
        { code: "+88", name: "Bangladesh" },
        { code: "+1", name: "United States" },
        { code: "+49", name: "Germany" },
        { code: "+44", name: "United Kingdom" },
        { code: "+91", name: "India" },
        // add more country codes as needed
    ];
    // console.log('This is Current user from CotextAPI', currentUser)

    return (
        <Container fluid className="userProfile">
            {/* <h1>This is from User Profile</h1> */}
            <Row>
                <div>
                    {/* <h3>Please Complete your Profile</h3> */}
                    <Image
                        fluid
                        className="d-block w-100 "
                        src={profileheader}
                        alt="First slide"
                    //  style={{ border: '2px solid green' }}
                    />
                </div>
            </Row>
            <Container className="mt-5">

                <Row>
                    <Col lg={3} md={6} sm={12}>
                        <div>
                            {/* <h3>Please Complete your Profile</h3> */}
                            <Image
                                fluid
                                className="d-block w-100 "
                                src={profileimg1}
                                alt="First slide"
                            //  style={{ border: '2px solid green' }}
                            />
                        </div>


                    </Col>
                    <Col lg={4} md={6} sm={12} className="user-form">
                        <div>
                            <h4>Your Personal Informations</h4>
                            <Form onSubmit={handleSubmit}>
                                {/* <Form.Group controlId="formPicture">
                            <Form.Label>Picture</Form.Label>
                            <Form.Control type="file" onChange={handlePictureChange} />
                        </Form.Group> */}
                                <Form.Group controlId="formName">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter your name"
                                        // value={currentUserId ? currentUser.name || '' : ''}
                                        onBlur={handleNameChange}
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please enter your name.
                                    </Form.Control.Feedback>
                                </Form.Group>


                                <Form.Group controlId="address">
                                    <Form.Label>Address</Form.Label>

                                    <Row>
                                        <Col>
                                            <Form.Group controlId="street">
                                                <Form.Label>Street</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter street"
                                                    value={street}
                                                    onChange={(event) => setStreet(event.target.value)}
                                                />
                                            </Form.Group>
                                        </Col>

                                        <Col>
                                            <Form.Group controlId="city">
                                                <Form.Label>City</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter city"
                                                    value={city}
                                                    onChange={(event) => setCity(event.target.value)}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <Form.Group controlId="country">
                                                <Form.Label>Country</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter country"
                                                    value={country}
                                                    onChange={(event) => setCountry(event.target.value)}
                                                />
                                            </Form.Group>
                                        </Col>


                                    </Row>
                                </Form.Group>

                                <Form.Group controlId="phon-number">

                                    <Form.Label>Phone Number</Form.Label>
                                    <Row>
                                        <Col xs={4}>
                                            <Form.Select
                                                value={countryCode}
                                                onChange={(event) => setCountryCode(event.target.value)}
                                            >
                                                {countryCodes.map((code) => (
                                                    <option key={code.code} value={code.code}>
                                                        {code.name} ({code.code})
                                                    </option>
                                                ))}
                                            </Form.Select>
                                        </Col>
                                        <Col xs={8}>
                                            <Form.Control
                                                type="tel"
                                                placeholder="Enter phone number"
                                                value={phone}
                                                onChange={(event) => setPhone(event.target.value)}
                                            />
                                        </Col>
                                    </Row>

                                </Form.Group>

                                <Form.Group controlId="formUniversity">
                                    <Form.Label>University</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter your university"
                                        // value={currentUser.university || ''}
                                        onChange={handleUniversityChange}
                                    />
                                </Form.Group>

                                <Form.Group controlId="formProfession">
                                    <Form.Label>Profession</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter your profession"
                                        // value={currentUser.profession || ''}
                                        onChange={handleProfessionChange}
                                    />
                                </Form.Group>

                                {/* <Form.Group>
                    <Form.Label>Profile Picture</Form.Label>
                    <Form.File
                        id="custom-file"
                        label="Choose file"
                        onChange={handlePictureChange}
                        accept="image/*"
                    />
                </Form.Group> */}
                                <Button variant="warning" className="m-2 p-2" type="submit" style={{ fontWeight: 'bold' }}>
                                    Create Profile
                                </Button>
                                <Button variant="warning" className="m-2 p-2" disabled={!isButtonEnabled} > <Link to={`/updateuser/${currentUser._id}`} style={{ color: 'Black', fontWeight: 'bold', textDecoration: 'none' }} >Edit Profile</Link></Button>
                            </Form>
                        </div>
                    </Col>
                    <Col lg={3} md={6} sm={12}>

                        <h3>Profile Summary</h3>
                        <p style={{ color: 'green' }}>{currentUser ? 'Congartulations!' || "" : ''}</p>
                        <p>{currentUser || userProfile ?
                            `${currentUser.name || userProfile.name || ""}, ${currentUser.Myemail || ""}, You have successfully created the profile. Do you want to Edit info? Just Click on Edit Profile` || `${userProfile.name}` : profileStatus}</p>

                    </Col>
                </Row>
            </Container>
        </Container>
    );
};




export default UserProfile;
