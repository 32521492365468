import React from 'react';
import { Button, Col, Container, Image, ListGroup, Row } from 'react-bootstrap';
import courseImage3 from '../../../Utilities/Images/3.PNG'
import courseImage2 from '../../../Utilities/Images/2.PNG'

import './Moredetails.css';
import CourseCard from './courseCard';
import { Link } from 'react-router-dom';
import { Border } from 'react-bootstrap-icons';

const Moredetails = () => {
    return (
        <Container className="ps-5 pe-5 pt-5">
            <Row className="MDtitle-dev" style={{ textAlign: 'justify', color: 'white' }}>
                <h3>Course Title: Simulation for Industrial Application: Fluid and Heat Transfer analysis </h3>
                <h5>Batch: 01</h5>
            </Row>

            <Row>

                <Col lg={6} className="" style={{ textAlign: 'justify', color: 'white' }}>
                    <h3>What is CFD?</h3>
                    <p >
                        Computational Fluid Dynamics (CFD) is a powerful tool for simulating and analyzing fluid flow and heat transfer problems. This course is designed as an introductory program for those who want to start learning CFD and want to take their skills to the advance level. It covers the fundamental principles of CFD, its application in engineering, industry, and research, and provides hands-on experience in using CFD software for problem setup, analysis, and result interpretation.

                    </p>

                    <h3>What is system Design?</h3>
                    <p style={{ textAlign: 'justify' }}>System design for fluid and thermal analysis applications involves the creation and optimization of complex fluid and thermal systems to meet specific performance criteria.

                        <p style={{ textAlign: 'justify' }} >System design for fluid and thermal analysis applications is a multidisciplinary process that requires expertise in fluid dynamics, heat transfer, mechanical engineering, control systems, and often computer-aided design (CAD) and simulation software. The goal is to create efficient, reliable, and safe systems that meet specific performance objectives while considering practical constraints.</p>

                    </p>

                </Col>
                <Col lg={1}>


                </Col>
                <Col lg={5}>
                    <Row >

                        <Image src={courseImage3} className='Brochure-image'>

                        </Image>



                    </Row>

                </Col>
            </Row>
            <Row className='Course-features-Container'>
                <Col lg={4}>
                    <Row className='feture-Image' >

                        <Image src={courseImage2} className='Brochure-image'>

                        </Image>
                    </Row>

                </Col>
                <Col lg={7}>
                    <h4 className='Course-features-title'>Course Features</h4>
                    <ListGroup as="ol" numbered className='Course-features'>
                        <ListGroup.Item
                            as="li"
                            className="d-flex justify-content-between align-items-start Course-features"
                        >
                            <div className="ms-2 me-auto">
                                <div className="fw-bold">Complete Fluid and Thermal Analysis Solution:</div>
                                This course offers a holistic approach to fluid and thermal analysis, covering all aspects of these domains, making it a one-stop solution for individuals looking to master these subjects.                            </div>

                        </ListGroup.Item>
                        <ListGroup.Item
                            as="li"
                            className="d-flex justify-content-between align-items-start Course-features"
                        >


                            <div className="ms-2 me-auto">
                                <div className="fw-bold">Expertise in Fluid and Heat Transfer System Design:</div>

                                By completing this course, students will gain expertise in designing fluid and heat transfer systems, equipping them with the skills needed to excel in both research and industrial settings.
                            </div>

                        </ListGroup.Item>
                        <ListGroup.Item
                            as="li"
                            className="d-flex justify-content-between align-items-start Course-features"
                        >
                            <div className="ms-2 me-auto">
                                <div className="fw-bold">Versatility:</div>

                                The course caters to a wide audience, including researchers and professionals in various industries, making it suitable for those pursuing different career paths within the fluid and thermal analysis domain.
                            </div>

                        </ListGroup.Item>
                        <ListGroup.Item
                            as="li"
                            className="d-flex justify-content-between align-items-start Course-features"
                        >
                            <div className="ms-2 me-auto">
                                <div className="fw-bold">Comprehensive Coverage: </div>

                                It covers a broad range of topics, including 1D, 2D, and 3D simulations, programming, 3D CFD (Computational Fluid Dynamics) simulations, and fluid and thermal network design using tools like Simulink Simscape.                        </div>

                        </ListGroup.Item>


                        <ListGroup.Item
                            as="li"
                            className="d-flex justify-content-between align-items-start Course-features"
                        >
                            <div className="ms-2 me-auto">
                                <div className="fw-bold">Simulation Proficiency: </div>

                                Students will become proficient in conducting simulations in one, two, and three dimensions, allowing them to tackle problems of varying complexity.

                            </div>

                        </ListGroup.Item>


                        <ListGroup.Item
                            as="li"
                            className="d-flex justify-content-between align-items-start Course-features"
                        >
                            <div className="ms-2 me-auto">
                                <div className="fw-bold">Programming Skills:  </div>

                                The course includes programming components, ensuring that students can harness the power of coding to address fluid and thermal challenges effectively.
                            </div>

                        </ListGroup.Item>

                        <ListGroup.Item
                            as="li"
                            className="d-flex justify-content-between align-items-start Course-features"
                        >
                            <div className="ms-2 me-auto">
                                <div className="fw-bold">Applicability:  </div>

                                The course content is directly applicable to real-world problems, making graduates well-prepared to tackle challenges in industries such as automotive, aerospace, energy, and manufacturing.                        </div>

                        </ListGroup.Item>
                        <ListGroup.Item
                            as="li"
                            className="d-flex justify-content-between align-items-start Course-features"
                        >
                            <div className="ms-2 me-auto">
                                <div className="fw-bold">Career Advancement:  </div>
                                Completing this course can significantly boost career prospects, as it equips individuals with in-demand skills in fluid and thermal analysis, allowing them to pursue diverse and rewarding career paths.


                            </div>

                        </ListGroup.Item>




                    </ListGroup>


                </Col>

            </Row>
            <Row className="course-objectives">
                <Col lg={5} style={{ textAlign: 'justify', color: 'white' }}>


                    <h3>Objectives</h3>
                    <ListGroup as="ol" numbered>
                        <ListGroup.Item
                            as="li"
                            className="d-flex justify-content-between align-items-start"
                        >
                            <div className="ms-2 me-auto">
                                <div className="fw-bold">Understand the Fundamental Principles of Fluid Dynamics</div>
                                Gain a solid foundation in the fundamental principles governing the behavior of fluids, including continuity, momentum, and energy equations.
                            </div>

                        </ListGroup.Item>
                        <ListGroup.Item
                            as="li"
                            className="d-flex justify-content-between align-items-start"
                        >
                            <div className="ms-2 me-auto">
                                <div className="fw-bold">Acquire Numerical Skills:</div>
                                Develop proficiency in numerical methods used to discretize and solve fluid flow equations, such as finite difference, finite volume, and finite element methods.
                            </div>

                        </ListGroup.Item>
                        <ListGroup.Item
                            as="li"
                            className="d-flex justify-content-between align-items-start"
                        >
                            <div className="ms-2 me-auto">
                                <div className="fw-bold">Master CFD Software:</div>
                                Become proficient in using CFD software packages like ANSYS Fluent, SolidWorks Flow Simulation, MATLAB, Simulink, Simscape for setting up, solving, and post-processing fluid flow simulations and Fluid and thermal network analysis.
                            </div>

                        </ListGroup.Item>
                        <ListGroup.Item
                            as="li"
                            className="d-flex justify-content-between align-items-start"
                        >
                            <div className="ms-2 me-auto">
                                <div className="fw-bold">Apply CFD to Industrial Problems:</div>
                                Apply CFD techniques to solve real-world industrial problems across various sectors, including automotive, aerospace, energy, and manufacturing.
                            </div>

                        </ListGroup.Item>
                        <ListGroup.Item
                            as="li"
                            className="d-flex justify-content-between align-items-start"
                        >
                            <div className="ms-2 me-auto">
                                <div className="fw-bold"> Design Thermal and Fluid Systems:</div>
                                Gain the knowledge and tools to design thermal and fluid systems, including heat exchangers, HVAC systems, and cooling systems for electronics.
                            </div>

                        </ListGroup.Item>
                        <ListGroup.Item
                            as="li"
                            className="d-flex justify-content-between align-items-start"
                        >
                            <div className="ms-2 me-auto">
                                <div className="fw-bold"> Utilize MATLAB SimuLink/SimScape:</div>
                                Learn to use MATLAB SimuLink/SimScape for system design, modeling, and simulation of thermal and fluid systems.                        </div>

                        </ListGroup.Item>
                    </ListGroup>


                </Col>
                <Col lg={1}>
                </Col>
                <Col lg={5} style={{ textAlign: 'justify', color: 'white' }}>
                    <h3>I have completed the course and what are my career prospects?</h3>


                    <p style={{ textAlign: 'justify', padding: '50px' }}>Completing a course in Simulation for Industrial Applications: Thermal/Fluid System Design can open up a wide range of career possibilities in various industries. Here are some career paths and possibilities for individuals who have completed such a course:</p>

                    <ListGroup as="ol" numbered className="fw-b">
                        <ListGroup.Item as="li" style={{ fontWeight: 'bold' }}>Home Appliances (Such as Refrigerator, Air Conditioning Manufacturing Industry, Plastic Product Development, Mold Development etc.)</ListGroup.Item>
                        <ListGroup.Item as="li" style={{ fontWeight: 'bold' }}>Automotive (e.g., Vehicle Aerodynamics, Components Development, Heating or Cooling System Development etc.) </ListGroup.Item>
                        <ListGroup.Item as="li" style={{ fontWeight: 'bold' }}>Energy (e.g., Turbine Development, Engine Combustion analysis, Heat Exchanger optimization, Power Plant Design etc.) </ListGroup.Item>
                        <ListGroup.Item as="li" style={{ fontWeight: 'bold' }}>
                            Construction (e.g. Building HVAC design, Energy Optimization, Water supply System Design, Plant Design) and Many more sectors are open to work

                        </ListGroup.Item>
                        <ListGroup.Item as="li" style={{ fontWeight: 'bold' }}>
                            Whether you want to works as a Researcher or academic professional, this course is perfect match for you

                        </ListGroup.Item>
                    </ListGroup>

                    <Button variant="danger" as={Link} to='/coursedescription' className='m-3'>Enroll Now</Button>
                </Col>
            </Row>

            {/* <Row className='' style={{ border: '2px solid red', marginBottom: '100px' }}>
                <Col lg={3}>

                    Home || Course Description
                </Col>
            </Row> */}

        </Container>
    );
};

export default Moredetails;