import React, { useState } from 'react';
import { useEffect } from 'react';
import { useContext } from 'react';
import { Col, Container, Form, FormGroup, FormLabel, Nav, NavItem, NavLink, Row, Tab, TabContent, TabPane, Tabs } from 'react-bootstrap';
import { FaUser, FaMapMarkerAlt, FaGraduationCap, FaCode, FaClipboardList, FaRegStar, FaDownload } from 'react-icons/fa';
import { userContext } from '../../../../hooks/UseContextProvider';
import ModalforEdit from '../../../../Modal/Modal';
import Modal from '../../../../Modal/Modal';
import ModalEdu from '../../../../Modal/ModalEdu';
import ModalSkills from '../../../../Modal/ModalSkills';

import './HorizontalTab.css';

const HorizontalTab = () => {
    const [activeTab, setActiveTab] = useState('1');
    const { currentUser, usersData, usersProfile } = useContext(userContext);

    // const [currentUserN, setCurrentUserN] = useState([])
    // console.log('Current user form TAb', currentUser)

    return (
        <div>
            <Tabs
                defaultActiveKey="profile"
                transition={false}
                id="noanim-tab-example"
                className="mb-3"
            >
                <Tab eventKey="profile" title={<span><FaUser /> Profile</span>}>
                    {/* <Container /> */}
                    <p>{currentUser.name || ''}</p>
                    {/* <p>{usersData.data.Email}</p> */}
                </Tab>
                <Tab eventKey="address" title={<span><FaMapMarkerAlt></FaMapMarkerAlt> Address</span>}>
                    {/* <Container /> */}
                    {/* <h3>Address {currentUser.address}</h3> */}
                    {/* <Modal></Modal> */}
                    <Form>
                        <Col lg={3}>
                            <Form.Group controlId="formName">
                                <Form.Label>Street Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={currentUser.street || ''}
                                // onChange={handleNameChange}

                                />

                            </Form.Group>

                        </Col>
                        <Col lg={3}>

                            <Form.Group controlId="formName">
                                <Form.Label>City</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={currentUser.city || ''}
                                // onChange={handleNameChange}

                                />
                            </Form.Group>
                        </Col>
                        <Col lg={3}>

                            <Form.Group controlId="formName">
                                <Form.Label>Country</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={currentUser.country || ''}
                                // onChange={handleNameChange}

                                />
                            </Form.Group>
                        </Col>


                    </Form>
                    <ModalforEdit></ModalforEdit>
                </Tab>
                <Tab eventKey="education" title={<span><FaGraduationCap></FaGraduationCap> Education</span>} >

                    <Row>
                        <Col lg={3}>

                            <Form.Group controlId="formName">
                                <Form.Label>University</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={currentUser.university || ''}
                                // onChange={handleNameChange}

                                />
                            </Form.Group>
                        </Col>
                        <Col lg={3}>

                            <Form.Group controlId="formName">
                                <Form.Label>Highest Degree</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={currentUser.degree || ''}
                                // onChange={handleNameChange}

                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <ModalEdu></ModalEdu>
                </Tab>
                <Tab eventKey="skills" title={<span><FaCode></FaCode> Skills</span>} >
                    <Row>
                        <Col lg={3}>

                            <Form.Group controlId="formName">
                                <Form.Label>My Skills</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={currentUser.skills || ''}
                                // onChange={handleNameChange}


                                />
                            </Form.Group>
                            <ModalSkills></ModalSkills>
                        </Col>

                    </Row>
                </Tab>
                <Tab eventKey="projects" title={<span><FaClipboardList></FaClipboardList> Projects</span>} >
                    This is from projects
                </Tab>




                <Tab eventKey="activities" title={<span style={{ background: 'white', padding: '5px' }}><FaRegStar></FaRegStar> Course Materials</span>}>
                    <div className='display-Materials'>
                        <a href="/Course_Introduction.pdf" download="Course_Introduction.pdf" style={{ fontWeight: 'bold', padding: '5px', marginLeft: '10px', color: 'black', textDecoration: 'none' }}>
                            <span>1. Course Introduction <FaDownload /></span>
                        </a>

                        <br />
                        <a href="/Module_1_Introduction_to_CFD.pdf" download="Module_1_Introduction_to_CFD.pdf" style={{ fontWeight: 'bold', padding: '5px', marginLeft: '10px', color: 'black', textDecoration: 'none' }}>
                            <span>2. Module-1 Introduction to CFD <FaDownload /></span>
                        </a>
                        <br />
                        <a href="/Module_2_Numerical_Methods.pdf" download="Module_2_Numerical_Methods.pdf" style={{ fontWeight: 'bold', padding: '5px', marginLeft: '10px', color: 'black', textDecoration: 'none' }}>
                            <span>3. Module_2_Numerical_Methods.pdf <FaDownload /></span>
                        </a>
                        <br />
                        <a href="/Module_3_FDM_discretisation_and_CFD_Strategy.pdf" download="Module_3_FDM_discretisation_and_CFD_Strategy.pdf" style={{ fontWeight: 'bold', padding: '5px', marginLeft: '10px', color: 'black', textDecoration: 'none' }}>
                            <span>4. Module_3_FDM_discretisation_and_CFD_Strategy.pdf<FaDownload /></span>
                        </a>
                        <br />
                        <a href="/Module_3___Dealing_with_non_linearity.pdf" download="Module_3___Dealing_with_non_linearity.pdf" style={{ fontWeight: 'bold', padding: '5px', marginLeft: '10px', color: 'black', textDecoration: 'none' }}>
                            <span>5. Module_3___Dealing_with_non_linearity.pdf<FaDownload /></span>
                        </a>
                        <br />
                        <a href="/Module_4_Navier_Stokes_equations.pdf" download="Module_4_Navier_Stokes_equations.pdf" style={{ fontWeight: 'bold', padding: '5px', marginLeft: '10px', color: 'black', textDecoration: 'none' }}>
                            <span>6. Module_4_Navier_Stokes_equations.pdf<FaDownload /></span>
                        </a>

                        <br />
                        <a href="/TwoDPipeFlow.m" download="TwoDPipeFlow.m" style={{ fontWeight: 'bold', padding: '5px', marginLeft: '10px', color: 'black', textDecoration: 'none' }}>
                            <span>7. Module_12 WS-2 TwoDPipeFlow.m<FaDownload /></span>
                        </a>



                    </div>



                </Tab>


            </Tabs>
        </div >
    );
}

export default HorizontalTab;
