import { useContext } from 'react';
import { Outlet, Navigate, useLocation, Link } from 'react-router-dom'
import { userContext } from './UseContextProvider';
import useAuth from './useAuth';
import { Button } from 'react-bootstrap';

const PrivateRoute = () => {
    const { usersProfile, usersData, currentUser } = useContext(userContext);
    let auth = currentUser._id;

    const firebase = useAuth();
    const location = useLocation();
    const { pathname } = location;


    console.log('This is current user in Private Route', currentUser._id)
    // console.log('This is current user in Private Route', currentUser._id)

    return (
        currentUser._id ? <Outlet /> : <Link to='/Signin'><Button variant='outline-danger'>Signin Please</Button></Link>
    )
}

export default PrivateRoute