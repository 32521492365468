import React, { useContext } from 'react';
import { Container, Navbar, Nav, NavDropdown, Button, Row } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link, useNavigate } from 'react-router-dom';
import './Header.css'
import { FaSignOutAlt, FaSignInAlt } from 'react-icons/fa';

import useAuth from '../hooks/useAuth';

import logo from '../../Utilities/Images/bdsimlab3.png'
import { userContext } from '../hooks/UseContextProvider';

const Header = () => {
  const { firebaseData } = useAuth();

  const { currentUser } = useContext(userContext);

  const Nevigate = useNavigate();

  const { user } = firebaseData
  const loginStatus = (status) => {
    if (user.email) {
      status = true

    }
    else {
      status = false;
    }

    return status;
  }

  console.log('Login status is', loginStatus(), user)

  const handleLogout = () => {
    const SignInStatus = loginStatus()
    if (SignInStatus == true) {
      firebaseData.logOut()

    }
  }
  const handleMyAccount = () => {
    if (currentUser._id) {
      Nevigate(`/bdsimulLab01/${currentUser._id}`)
      console.log('This is nevigation')
    }
    else {
      Nevigate(`/bdsimulLab01`)
    }
  }
  // const dynamicPath = `/bdsimulLab01/${currentUser._id}`;




  return (


    <Container className="header">


      <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
        <Container>
          <Navbar.Brand ><Nav.Link as={Link} to="/" >

            <Row className='logo'>
              <img src={logo}></img>
            </Row>

          </Nav.Link></Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link as={Link} to="about" > About
              </Nav.Link>
              <Nav.Link as={Link} to="/">Events</Nav.Link>
              <Nav.Link as={Link} to="/">Blog</Nav.Link>
              <Nav.Link as={Link} to="/mecourse">My Course</Nav.Link>
              <Nav.Link as={Link} to="/cambassedor">Campus Ambassadors</Nav.Link>



              {/* <NavDropdown title="My Learning" id="collasible-nav-dropdown"> */}
              {/* <NavDropdown.Item href="#action/3.1">Workshop</NavDropdown.Item> */}
              {/* <NavDropdown.Item href="#action/3.2">
                  <Link to="/mecourse">Courses</Link>
                </NavDropdown.Item> */}
              {/* <NavDropdown.Item href="#action/3.2"> */}
              {/* <Nav.Link as={Link} to="" style={{ color: "black" }} > My Courses */}
              {/* </Nav.Link> */}
              {/* </NavDropdown.Item> */}

              {/* <NavDropdown.Item href="#action/3.4"> */}

              {/* </NavDropdown.Item> */}
              {/* </NavDropdown> */}




              <NavDropdown title="Services" id="collasible-nav-dropdown">
                {/* <NavDropdown.Item href="#action/3.1">Simulation</NavDropdown.Item> */}
                <NavDropdown.Item href="#action/3.2">
                  {/* CAD Design */}
                </NavDropdown.Item>
                {/* <NavDropdown.Item href="#action/3.3">Thesis and Research</NavDropdown.Item> */}
                <NavDropdown.Divider />
                <NavDropdown.Item href="#action/3.4">

                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
            <Nav>

              {/* {!currentUser.name && (<Nav.Link as={Link} to="/">Register</Nav.Link>)} */}


              <Nav.Link as={Link} to="/signin">
                {user.email ? <Button variant="outline-success" onClick={handleLogout} >
                  {currentUser.name} <FaSignOutAlt></FaSignOutAlt></Button> : <Button variant="warning"><FaSignInAlt></FaSignInAlt> SignIn</Button>


                }
              </Nav.Link>

              <Nav.Link onClick={handleMyAccount}>My Account</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

    </Container>






  );
};

export default Header;