import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import './DetailsProfile.css';
import { CgProfile } from 'react-icons/cg'
import { MdPlace, MdWorkOutline } from 'react-icons/md'
import { FaLinkedin, FaRegEnvelope } from 'react-icons/fa'
import { BsBook } from 'react-icons/bs'
import { GiSkills } from 'react-icons/gi'
import { FiPhoneCall } from 'react-icons/fi'
import { GoProject } from 'react-icons/go'
// import ProfileTab from '../ProfileTab/ProfileTab';
import HorizontalTab from './HorizontalTab/HorizontalTab';
import { useContext } from 'react';
import { userContext } from '../../../hooks/UseContextProvider';
import useFirebase from '../../../hooks/useFirebase';
import useAuth from '../../../hooks/useAuth';
import ModalIntro from '../../../Modal/ModalIntro';
// import HorizontalTab from './HorizontalTab/HorizontalTab';
// import HorizontalTab from './HorizontalTab/HorizontalTab';


const DetailsProfile = () => {
    // const { currentUser } = useContext(userContext);
    const [name, setName] = useState('');
    const [Email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [profession, setProfession] = useState('');
    const { currentUser } = useContext(userContext);

    // const [currentUser, setCurrentUser] = useState('');
    const auth = useAuth();

    const firebaseData = useFirebase();
    const FirebaseuserEmail = firebaseData.user.email;





    // useEffect(() => {

    //     setCurrentUser('');
    //     // console.log('Here is firebase data', FirebaseuserEmail)
    //     fetch('http://localhost:5000/userprofiledb')
    //         .then(res => res.json())
    //         .then(data => {
    //             console.log('Here is Userprofile Database', data)
    //             setCurrentUser(data);

    //             data.map((user) => {
    //                 // console.log('This are users', user.Email);
    //                 // console.log('This are users', user);
    //                 // console.log('This are users email', user.data.Email, FirebaseuserEmail);
    //                 if (user.Email === FirebaseuserEmail) {
    //                     setCurrentUser(user)

    //                     console.log('this is curret user in Details Profile', user)
    //                 }

    //             })

    //         })

    //     // if (!FirebaseuserEmail) {
    //     //     setCurrentUser('')

    //     //     console.log('User logged out')
    //     // }
    // }, [FirebaseuserEmail, auth]);

    useEffect(() => {
        if (currentUser) {
            // console.log('Current user found', currentUser.data.name)
            // setName(currentUser.data)

        }
    }, [currentUser])



    console.log('This is current user from Details Profile', currentUser)


    return (
        <Container fluid className='detailsProfile'>
            <Row className='profile-Cover'>
                <Col lg={6} s={4} xs={12}>
                    <p className='userTitle'>{currentUser.profession || ''}</p>
                </Col>
                <Col lg={4} s={12} xs={12} className='Personal-info'>
                    <h3 className='userName'>{currentUser.name || ''}</h3>
                    <p><FaRegEnvelope></FaRegEnvelope> {currentUser.Email || 'Somthing went wrong. Please check your network connection'}</p>
                    <p><FiPhoneCall></FiPhoneCall> {currentUser.phone || ''}</p>
                    {/* <p><FaLinkedin></FaLinkedin> shmanikduet@gmail.com</p> */}
                </Col>

            </Row>

            <Row className="about-user">
                <Col lg={8} className="aboutuser-column">
                    <Container>
                        About me:
                        <p>{currentUser.intro}</p>

                        <ModalIntro></ModalIntro>
                    </Container>
                </Col>
            </Row>
            <Container>
                {/* <Row className="profile-headers">
                    <Col lg={2}><CgProfile></CgProfile> My Profile </Col>
                    <Col lg={2}><MdPlace></MdPlace> Address</Col>
                    <Col lg={2}><BsBook></BsBook> Education</Col>
                    <Col lg={2}><GiSkills></GiSkills> Skills</Col>
                    <Col lg={2}><GoProject> </GoProject> Projects</Col>
                    <Col lg={2}><MdWorkOutline></MdWorkOutline> Activities</Col>
                </Row> */}

                <Row>
                    <HorizontalTab></HorizontalTab>
                </Row>

            </Container>

        </Container>
    );
};

export default DetailsProfile;