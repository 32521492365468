import React, { useState } from 'react';
import { Form, Button, Container, Row, Col, Modal } from 'react-bootstrap';
import './RegistrationFormCA.css';
import { FaRegAddressBook, FaRegImage } from 'react-icons/fa';
import { useEffect } from 'react';

function RegistrationForm() {
    const [password, setPassword] = useState('');
    const [pwStatus, setPwStatus] = useState('');
    const [step, setStep] = useState(1);

    const [name, setName] = useState('');
    const [university, setUniversity] = useState('');
    const [department, setDepartment] = useState('');
    const [semester, setSemester] = useState('');
    const [mobile, setMobile] = useState('');
    const [email, setEmail] = useState('');

    const [showModal, setShowModal] = useState(false); // State for the confirmation modal

    const [CAuser, setCAUsersProfile] = useState([]);
    const [errorModal, setErrorModal] = useState(false); // State for the error modal
    const [duplicateEmail, setDuplicateEmail] = useState(null);




    const handleCloseModal = () => {
        setShowModal(false);
        setErrorModal(false);
    };
    useEffect(() => {
        // Fetch the CAuser data when the component mounts
        fetch('https://bd-simulab.onrender.com/campusambassadors')
            .then((response) => response.json())
            .then((data) => {
                setCAUsersProfile(data);
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }, [email]);
    const handleSubmitPassword = (e) => {
        e.preventDefault();

        // Check if the entered password is correct
        if (password === 'SECRET') {
            setStep(2); // Move to step 2 (personal information)
            setPwStatus('');
        } else {
            console.error('Incorrect password');
            setPwStatus('***Please enter the correct password');
        }
    };

    const handleSubmitPersonalInfo = (e) => {
        e.preventDefault();
        setDuplicateEmail(null);

        // Check for duplicate emails using locally fetched data
        const duplicateEmailFound = CAuser.some((ca) => ca.email === email);

        if (duplicateEmailFound) {
            // Show the error modal for duplicate email
            setDuplicateEmail(email);
            setErrorModal(true);
        } else {
            // Continue with the registration
            const formData = {
                name,
                university,
                department,
                semester,
                mobile,
                email,
            };
            fetch('https://bd-simulab.onrender.com/campusambassadors', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            })
                .then((registrationResponse) => {
                    if (registrationResponse.status === 200) {
                        console.log('Registration successful');
                        setShowModal(true); // Show the confirmation modal
                        // Reset form fields
                        setName('');
                        setUniversity('');
                        setDepartment('');
                        setSemester('');
                        setMobile('');
                        setEmail('');
                        setPassword('');
                        setStep(1); // Reset the step to 1
                    } else {
                        console.error('Registration failed');
                    }
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        }
    };

    return (
        <Container className='mt-3'>
            <Row className="CA-regContainer">
                <Col lg={3} className="CA-Form">
                    <h2>
                        <FaRegAddressBook />
                        Registration form
                    </h2>
                    {step === 1 && (
                        <Form onSubmit={handleSubmitPassword}>
                            <Form.Group controlId="password">
                                <Form.Label>Password</Form.Label>
                                <Form.Control
                                    type="password"
                                    placeholder="Enter your password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                />
                            </Form.Group>
                            <p style={{ color: 'red' }}>{pwStatus}</p>
                            <Button variant="outline-primary" type="submit" className="m-2">
                                Continue
                            </Button>
                        </Form>
                    )}
                    {step === 2 && (
                        <Form onSubmit={handleSubmitPersonalInfo} className="">
                            <Form.Group controlId="name">
                                <Form.Label>Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter your name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    required
                                />
                            </Form.Group>

                            <Form.Group controlId="university">
                                <Form.Label>University</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter your university"
                                    value={university}
                                    onChange={(e) => setUniversity(e.target.value)}
                                    required
                                />
                            </Form.Group>

                            <Form.Group controlId="department">
                                <Form.Label>Department</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter your department"
                                    value={department}
                                    onChange={(e) => setDepartment(e.target.value)}
                                    required
                                />
                            </Form.Group>

                            <Form.Group controlId="semester">
                                <Form.Label>Semester</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter your semester"
                                    value={semester}
                                    onChange={(e) => setSemester(e.target.value)}
                                    required
                                />
                            </Form.Group>

                            <Form.Group controlId="mobile">
                                <Form.Label>Mobile Number</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter your mobile number"
                                    value={mobile}
                                    onChange={(e) => setMobile(e.target.value)}
                                    required
                                />
                            </Form.Group>

                            <Form.Group controlId="email">
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder="Enter your email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                />
                            </Form.Group>
                            <Button
                                variant="outline-warning"
                                type="submit"
                                className="m-2"
                            >
                                Submit Registration
                            </Button>
                        </Form>
                    )}
                </Col>
            </Row>
            {/* Confirmation Modal */}
            <Modal
                show={showModal}
                onHide={handleCloseModal}
                contentClassName="modal-transparent-bg"
            >
                <Modal.Header closeButton>
                    <Modal.Title style={{ color: 'cyan' }}>
                        <FaRegImage /> Registration Successful
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Your registration has been successfully submitted to BD-SimuLab.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={errorModal}
                onHide={handleCloseModal}
                contentClassName="modal-transparent-bg"
            >
                <Modal.Header closeButton>
                    <Modal.Title style={{ color: 'red' }}>
                        Error: Duplicate Email
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    This email address has already been registered.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
}

export default RegistrationForm;
