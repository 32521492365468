import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

import './blogs.css'// Import React Router components


const Blogs = () => {
    const [blogs, setBlogs] = useState([]);
    const [selectedBlog, setSelectedBlog] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");
    const [searchResult, setSearchResult] = useState(null);

    const [macthBlogs, setMatchBlogs] = useState([]);

    const navigate = useNavigate();

    // Get the blog ID from the URL using useParams
    const { id } = useParams();

    useEffect(() => {
        // Fetch blogs.json when the component mounts
        fetch("/blogs.json") // Adjust the path to your JSON file
            .then((response) => response.json())
            .then((data) => setBlogs(data))
            .catch((error) => console.error("Error fetching data:", error));
    }, []);

    useEffect(() => {
        // Find the blog with the matching ID in the URL
        if (id) {
            const foundBlog = blogs.find((blog) => blog.id === parseInt(id, 10));
            setSelectedBlog(foundBlog || null);
        }
    }, [id, blogs]);

    const handleSearch = (prop) => {

        setMatchBlogs([]);
        const searchTermLower = searchTerm.toLowerCase(); // Convert search term to lowercase

        const matchingBlogs = blogs.filter((blog) =>
            blog.title.toLowerCase().includes(searchTermLower)

        );
        console.log('This are matching blogs', matchingBlogs)
        setMatchBlogs(matchingBlogs)
        console.log('this are my result', macthBlogs)

        if (matchingBlogs.length > 0) {
            setSelectedBlog(null);

            console.log('Thi are Matched', searchResult)
            setSearchResult(
                matchingBlogs.map((blog) => (
                    <Col key={blog.id} md={6} className="mb-4">
                        <Card>

                            {selectedBlog && selectedBlog.img && (
                                <Card.Img
                                    variant="top"
                                    src={selectedBlog.img}
                                    alt={selectedBlog.title}
                                />
                            )}

                            <Card.Body>
                                <Card.Title>
                                    <Link to={`/blogs/${blog.id}`} style={{ textDecoration: 'none', color: 'black' }}>{blog.title}</Link>
                                </Card.Title>
                                <Card.Text>Published: {blog.publishedDate}</Card.Text>
                                <Card.Text>Author: {blog.author}</Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                ))
            );
        } else {
            setSelectedBlog(null);
            setSearchResult("Not available");
        }
    };

    const handleNevigateBlog = () => {
        navigate('/blogs');
    }



    return (
        <Container className="mt-5">

            <Row>
                <Col md={8} className="mb-4">
                    <div className="d-flex">
                        <input
                            type="text"
                            placeholder="Search by title"
                            className="form-control me-3"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                        <Button
                            variant="outline-primary"
                            className="ms-3 "
                            onClick={handleSearch}
                        >
                            Search
                        </Button>
                        {/* <Button onClick={handleNevigateBlog} className='m3'>Back to Blogs</Button> */}
                    </div>

                    <Row>
                        {searchResult}
                    </Row>
                    {searchResult === "Not available" && (
                        <div className="text-danger mt-2">Not available</div>
                    )}
                </Col>
            </Row>
            <Row className="blogs-container">
                {selectedBlog ? (
                    <Col md={8} className='blogs-details mt-5 mb-5'>
                        <Container>
                            <Card style={{ height: '400px' }}>
                                {selectedBlog && selectedBlog.img && (
                                    <Card.Img
                                        variant="top"
                                        src={selectedBlog.img}
                                        alt={selectedBlog.title}
                                    />
                                )}
                                <Card.Body>
                                    <Card.Title>{selectedBlog.title}</Card.Title>
                                    <Card.Text>Published: {selectedBlog.publishedDate}</Card.Text>
                                    <Card.Text>Author: {selectedBlog.author}</Card.Text>
                                    <Card.Text>{selectedBlog.details1}</Card.Text>
                                    <Card.Text>{selectedBlog.details2}</Card.Text>
                                    <Card.Text>{selectedBlog.details3}</Card.Text>
                                    <Card.Text>{selectedBlog.details4}</Card.Text>
                                    <Card.Text>{selectedBlog.details6}</Card.Text>
                                    <Card.Text>{selectedBlog.details7}</Card.Text>
                                </Card.Body>
                            </Card>
                        </Container>
                    </Col>
                ) : (
                    blogs.map((blog) => (
                        <Col key={blog.id} md={3} className="mb-4">
                            <Card>

                                {selectedBlog && selectedBlog.img && (
                                    <Card.Img
                                        variant="top"
                                        src={selectedBlog.img}
                                        alt={selectedBlog.title}
                                    />
                                )}

                                <Card.Body>
                                    <Card.Title>
                                        <Link to={`/blogs/${blog.id}`} style={{ textDecoration: 'none', color: 'black' }}>{blog.title}</Link>
                                    </Card.Title>
                                    <Card.Text>Published: {blog.publishedDate}</Card.Text>
                                    <Card.Text>Author: {blog.author}</Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))
                )}
            </Row>
        </Container>
    );
};

export default Blogs;
