import React from 'react';
import { Container, Row, Col, Button, Image } from 'react-bootstrap';
import { FaCopyright, FaEnvelope, FaEnvelopeOpenText, FaFacebook, FaInstagram, FaLinkedin, FaTwitter, FaVoicemail } from 'react-icons/fa';

import './Fooer.css'
const Footer = () => {
    return (
        <Row className="footer-div">
            <Col lg={2} xs={4} s={4}>
                {/* <h3>BD-SimuLab</h3> */}

                <Row>

                    <Col>

                        <Image className='w-50' src='bdsimlab.png'></Image>
                    </Col>
                </Row>

            </Col>
            <Col lg={5} xs={12} s={12} >
                <FaCopyright></FaCopyright> 2023 <br />
                All right reserved by BD-SimuLab

            </Col>
            <Col lg={5} xs={12} s={12}>
                Follow us on:
                <a href="https://www.facebook.com/groups/1678040259294430/" style={{ textDecoration: 'none', color: 'white', margin: '0 5px 0 10px' }}> <FaFacebook></FaFacebook></a>
                <a href="https://www.linkedin.com/groups/14206681/" style={{ textDecoration: 'none', color: 'white', margin: '0 5px 0 10px' }}> <FaLinkedin></FaLinkedin></a>

                <br></br> Join Facebook group @<a href="https://www.facebook.com/groups/1678040259294430/" style={{ textDecoration: 'none', color: 'white', margin: '0 5px 0 0px' }}>BD-SimuLab</a>
                <br /><p style={{ textDecoration: 'none', color: 'white', margin: '0 0px 60px 0px' }}><FaEnvelopeOpenText></FaEnvelopeOpenText> bdsimulab@gmail.com</p>


                {/* <FaTwitter></FaTwitter> */}
                {/* <FaInstagram></FaInstagram> */}
            </Col>
        </Row>
    );
};

export default Footer;