import React, { useContext, useEffect, useState } from 'react';
import Banner from '../Banner/Banner';
import { BsBook } from "react-icons/bs"
import Button from 'react-bootstrap/Button';

import './Home.css'
// import Container from 'react-bootstrap/Container';
import { Container, Row, Col, Card, Image, InputGroup, Form, Accordion } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { MdEmojiEvents } from 'react-icons/md'
import { FaBook } from 'react-icons/fa'

import { Link, useNavigate, useParams } from 'react-router-dom';
import { userContext } from '../hooks/UseContextProvider';
import UpdatedFeeds from './updatedFeeds/UpdatedFeeds';
import LeagTable from './LeagTable/LeagTable';
import TopPlayers from './TopPlayers/TopPlayers';
import cfdThermal from '../../Utilities/Images/cfdSimulation.png';
import introbanner from '../../Utilities/Images/introbanner.png';
import learn from '../../Utilities/Images/learn.png';
import finish from '../../Utilities/Images/finish.png';
import enroll from '../../Utilities/Images/enroll.png';
import hired from '../../Utilities/Images/hired.png';

import courseImage3 from '../../Utilities/Images/3.PNG'
import caddesign from '../../Utilities/Images/caddesign.png';
import gasTurbine from '../../Utilities/Images/gasTurbine.gif';
import pumpgif from '../../Utilities/Images/pumpcfd.gif';
import pipeFlowgif from '../../Utilities/Images/pipeFlow.gif';




import Events from '../Events/Events';
import Regform from '../Regform/Regform';
import MultiDivSlider from './MultiDivSlider/MultiCarousel';
import MultiCarousel from './MultiDivSlider/MultiCarousel';
import CardCarousel from './MultiDivSlider/CardCarousel';
import DateTimePicker from 'react-datetime-picker/dist/DateTimePicker';
import CourseCard from '../Courses/Coursedetails/courseCard';
import CountdownTimer from '../CountdownTimer/Coundowntimer';


const Home = () => {

    const { doctors } = useContext(userContext);
    const [value, onChange] = useState(new Date());

    const navigate = useNavigate();

    const handleAppointment = () => {
        navigate('/')
    }

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 4,
            slidesToSlide: 3 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
            slidesToSlide: 2 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        }
    };
    // const photos = [
    //     {
    //         src: {genHealthImg},
    //         width: 800,
    //         height: 600
    //     },
    //     {
    //         src: { HeartImg },
    //         width: 1600,
    //         height: 900
    //     }
    // ];

    // 


    return (
        <Container fluid className='home-dev'>
            <Col lg={12}>
                <Row className='banner-bg' >
                    <Col lg={6} s={12} >

                        {/* <Banner></Banner> */}
                        <Container fluid>
                            <Image
                                fluid
                                className="d-block w-100 p-3"
                                src='Airfoil.png'
                                alt="First slide"
                            // style={{ border: '2px solid green' }}
                            />
                        </Container>

                    </Col >

                    <Col lg={5} className='BannerIntro-Div' >
                        <Container fluid className="bannerintro-innerDiv">
                            <h3 className='fancy-box' style={{ color: 'goldenrod' }}>Mastering Engineering Computation: Fluid and Heat Transfer Analysis</h3>
                            <p className="introdescription">Enroll <span style={{ color: 'green' }}>BD</span>-<span style={{ color: 'goldenrod' }}>SimuLab's</span> latest course to learn Computational Fluid Dynamics (CFD), programming for CFD, CAD design and System Design</p>
                            <p className="introdescription"><span style={{ color: 'green' }}>BD</span>-<span style={{ color: 'goldenrod' }}>SimuLab</span> offers you the complete fluid and thermal analysis solution</p>


                            <Link to='/coursedescription'>  <Button variant='outline-primary' style={{ textweight: 'bold', margin: '5px' }}>Want to know more?</Button></Link>
                            <Row className='mt-3 justify-content-center'>
                                {/* <Col lg={8}><CountdownTimer></CountdownTimer> </Col> */}
                                <p className='text-danger'> 🔒 Enrollment Closed! 🔒 <br /> Thank you to everyone who enrolled in our programs! 🎓 The enrollment period is now closed. Stay tuned for updates as we will be announcing the next enrollment date soon. </p>
                            </Row>
                        </Container>


                    </Col>

                </Row>
                <Row style={{ backgroundColor: 'black', color: 'white', padding: '20px' }}>
                    <Container>
                        Home || <a href="#latest-blog" style={{ color: 'white', textDecoration: 'none' }}>Study</a> || <a href="#about" style={{ color: 'white', textDecoration: 'none' }}>About</a> || <a href="#home-FAQ" style={{ color: 'white', textDecoration: 'none' }}>FAQ</a>
                    </Container>
                </Row>
                <Row className='banner-bg' >


                    <Col lg={5} className='BannerIntro-Div' >
                        <Container fluid className="bannerintro-innerDiv">
                            <h3 style={{ paddingTop: '30px' }} className='fancy-box'>Enhance your expertise in product development to a higher level. Reformulate it once more.</h3>
                            <p style={{ textAlign: 'justify', padding: '30px' }} className="introdescription"> In order to ensure successful innovation and timely market entry, safety, precision, and reliability are critical attributes for any new product. Compliance with regulatory standards is also essential. This is why leading companies enlist the expertise of technical simulation and computational specialists in their product development process.</p>


                            {/* <Button variant='warning' style={{ textweight: 'bold' }}>Schedule your Consultaion Hour</Button> */}
                        </Container>

                    </Col>

                    {/* <Col lg={1}>
                    
                    
                    </Col> */}
                    <Col lg={3} className='mb-3 mt-3 enrollCard '>
                        <Row>
                            <CourseCard></CourseCard>
                        </Row>

                    </Col>

                    <Col lg={3} s={12} className='mb-3 mt-3 enrollCard'>

                        {/* <Banner></Banner> */}
                        <Row >
                            <Image
                                fluid
                                className="d-block w-100"
                                src={pumpgif}
                                alt="First slide"
                                style={{ marginLeft: '0px', sboxShadow: '0px 0px 10px 20px rgba(128, 128, 128, 0.853)' }}
                            />
                        </Row>

                    </Col >

                </Row>

                <Row>
                    <Container>
                        <MultiCarousel></MultiCarousel>
                    </Container>
                </Row>

                <Row className="procees-div banner-bg">
                    <div fluid style={{ border: '2px solid golden' }}>

                        <h3 className="bannerintro-innerDiv flying-text" style={{ color: 'gray', padding: '10px', marginBottom: '30px', marginLeft: '50px' }}>How does <span style={{ color: 'green' }}> BD</span>-<span style={{ color: 'goldenrod' }}>SimuLab</span> work?</h3>
                        <Row>
                            <Col lg={3} md={3} s={6} xs={6} className="section flip" >

                                <Image fluid src={enroll} alt="" />


                            </Col>
                            <Col lg={3} md={3} s={5} xs={6} className="section flip" >

                                <Image fluid src={learn} alt="" />


                            </Col>
                            <Col lg={3} md={3} s={6} xs={6} className="section flip" >

                                <Image fluid src={finish} alt="" />


                            </Col>
                            <Col lg={3} md={3} s={6} xs={6} className="section flip" >

                                <Image fluid src={hired} alt="" />


                            </Col>
                        </Row>
                    </div>





                </Row>

                <Row className='RnD-div' id='events'>
                    <Col lg={12} className="RnD-section" >
                        <Container fluid className="RnD-innerdiv" >

                            <div>
                                <p>You are one step ahead</p>
                                <h3 className='title-text'>to overcome your new R&D Challenge </h3>
                                <p>Project, Exercise, Workshop, Training ensure that you are utilizing your learning in your professional Environment</p>
                                <Link to='/events'><Button variant='outline-warning' style={{ fontWeight: '700' }}>Enroll for Latest Wabiner</Button></Link>
                            </div>

                            {/* <Image
                            fluid
                            src={pipeFlowgif}
                        >


                        </Image> */}


                        </Container>



                    </Col>
                </Row>

                {/* <Row id="latest-blog" >

                    <h3 className='fancy-box' style={{ padding: '20px' }}>Explore latest Blogs</h3>
                    <>


                        <Col className="card-Carousel" lg={3} md={6} s={12} xs={12} >
                            <CardCarousel></CardCarousel>
                        </Col>


                        <Col className="cardCarousel-descriptions" lg={6} s={12} md={5}>

                            <div>
                                <p>Discover our comprehensive blog on Computational Engineering, designed to take you from beginner to expert in this field. Gain insights into topics such as CFD and FEM analysis, and develop the theoretical knowledge necessary to understand the principles behind every engineering computation. With our informative and engaging content, you can enhance your knowledge and advance your career. Start your journey to mastery today!</p>
                                <Link to='/blogs'><Button variant='outline-success' className='blog-button' style={{ fontWeight: '600', }}>Explore All blogs here</Button></Link>
                            </div>
                        </Col>
                        <Col className="card-Carousel" lg={3} md={6} s={12} xs={12} >
                            <CardCarousel></CardCarousel>
                        </Col>

                    </>


                </Row> */}

                <Container className="Homeabout-container">
                    <Row id="about">
                        <Col lg={1}>

                        </Col>

                        <Col lg={6} md={5} >
                            <h3 style={{ padding: '0px', color: 'white' }}>What is <span style={{ color: 'green' }}>BD</span>-<span style={{ color: 'goldenrod' }}>SimuLab</span>?</h3>

                            <Container className='about-intro'>

                                <Row>
                                    <p style={{ textAlign: 'justify' }}>BD-SimuLab is an engineering computation center specializing in mechanical simulation for product and process development. With expertise in 3D CFD and FEMA simulation for thermal, fluid, and hydraulic systems, we offer comprehensive training workshops, webinars, and consultations to help the young professionals, engineering students and businesses achieve their product development goals. Our mission is to bridge the gap between academia and industry by preparing young engineers and professionals to meet the challenges of the modern workplace. Our training program offers a range of software platforms, including Solidworks Simulation, ANSYS Fluent, Autodesk CFD, and MATLAB Simulink/Simscap, providing individuals with the tools and confidence necessary to achieve their career objectives. Join BD-SimuLab today and take the next step in your professional journey!</p>
                                    <Link to='/about'><Button variant='outline-success'>Explore more</Button></Link>

                                </Row>
                            </Container>
                        </Col>
                        <Col lg={5} md={6} s={12} className="about-img"  >

                            <Image
                                fluid
                                className="d-block w-100 "
                                src={courseImage3}
                                alt="about image"
                            // style={{ height: '50vh' }}
                            />

                        </Col>
                    </Row>


                </Container>


                <Container>






                    <Container className="sections-FAQ" id='home-FAQ'>
                        <Row >
                            <Col lg={4} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

                                <Row>
                                    <h3 className="FAQ-Title">FAQs</h3>
                                    <Image src='FAQ.gif'></Image>
                                </Row>
                            </Col>

                            <Col lg={6} className='Home-FAQ'>
                                <Accordion>
                                    <Accordion.Item eventKey="0" style={{ backGroundColor: 'rgba(255, 255, 255, 0.19)' }}>
                                        <Accordion.Header>What is BD-SimuLab?</Accordion.Header>
                                        <Accordion.Body>
                                            BD-SimuLab is a platform for engineers and researchers interested in mechanical simulation.BD-SimuLab is an exciting initiative dedicated to empowering
                                            and supporting young engineers and professionals in developing their computational skills. Through workshops, training programs, and knowledge
                                            sharing sessions, BD-SimuLab provides a dynamic platform for individuals to enhance their expertise and explore new career opportunities.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>What types of simulations can be performed with BD-SimuLab?</Accordion.Header>
                                        <Accordion.Body>
                                            Any types of Simulation related to fluid and thermal analysis, Heat transfer.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header>Is BD-SimuLab suitable for beginners in the field of mechanical simulation?</Accordion.Header>
                                        <Accordion.Body>
                                            Yes, BD-SimuLab is designed to be accessible for users with varying levels of experience. Whether you are a begineer or Experieced professional, we always try to start each program from very beginner level.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3">
                                        <Accordion.Header>What analysis tools are available to learn in BD-SimuLab?</Accordion.Header>
                                        <Accordion.Body>
                                            You can learn here CAD Modeling, ANSYS Fluent, Solidworks Flow Simulation, Autodesk CFD, MATLAB Simulink/Simscap, Python.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="4">
                                        <Accordion.Header>What topics or areas of mechanical simulation are covered in the training programs?</Accordion.Header>
                                        <Accordion.Body>
                                            BD-Simulation covers a wide range of topics related to mechanical simulation. Some of the common areas include Computational Fluid Dynamics (CFD),Systenm Design, and other simulation techniques used in various engineering applications.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="5">
                                        <Accordion.Header>Who can benefit from the training programs offered by BD-Simulation?</Accordion.Header>
                                        <Accordion.Body>
                                            The training programs offered by BD-SimuLab are beneficial for a diverse audience. They cater to young students aspiring to pursue a career in engineering, engineers seeking to expand their simulation skills, and professionals who want to stay updated with the latest advancements in mechanical simulation.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="6">
                                        <Accordion.Header>What are the delivery methods for the training programs?</Accordion.Header>
                                        <Accordion.Body>
                                            BD-Simulation offers online training programs, allowing learners to access the courses from anywhere with an internet connection. The training materials, lectures, and assignments are delivered through a user-friendly online platform, making it convenient for participants to study at their own pace.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>

                            </Col>

                        </Row>


                    </Container>

                    {/* <Row>
                    <Regform></Regform>
                </Row> */}


                </Container>

            </Col>

        </Container >

    );
};

export default Home;