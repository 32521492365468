import React, { useEffect, useState } from 'react';
import { Button, Modal, Spinner, Form, InputGroup } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import useFirebase from '../hooks/useFirebase';
import axios from 'axios';

const SignInComponent = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isUserEnrolled, setIsUserEnrolled] = useState(false);
    const [isProfileCreated, setIsProfileCreated] = useState(false);
    const [createdProfile, setCreatedProfile] = useState({});
    const [enrolledStudentInfo, setEnrolledStudentInfo] = useState({});
    const firebaseData = useFirebase();
    const firebaseUserEmail = firebaseData.user?.email;
    const history = useNavigate();
    const [department, setDepartment] = useState('');
    const [University, setUniversity] = useState('');
    const [street, setStreet] = useState('');
    const [city, setCity] = useState('');
    const [country, setCountry] = useState('');
    const [profession, setProfession] = useState('');
    const [degree, setDegree] = useState('');
    const [phone, setPhone] = useState('');
    const [intro, setIntro] = useState('');
    const [skills, setSkills] = useState('');
    const [interest, setInterest] = useState('');
    const [profileFormData, setProfileFormData] = useState({
        name: '',
        email: '',
        mobile: '',
        department: '',
        university: '',
    });

    const handleProfileFormChange = (e) => {
        const { name, value } = e.target;
        setProfileFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleProfileCreation = async () => {
        setIsLoading(true);
        const name = enrolledStudentInfo.FullName;
        const Email = enrolledStudentInfo.Email;
        const phone = enrolledStudentInfo.MobileNumber;
        const university = University;
        const degree = department;

        const myNewProfile = {
            name,
            Email,
            phone,
            street,
            city,
            country,
            degree,
            university,
            profession,
            skills,
            intro,
            interest,
        };

        const emailData = {
            to: Email, // Replace with the recipient's email address
            subject: 'Portal Successfully Created',
            message: `Dear ${name}, \nWe are pleased to inform you that the online portal for the course 'Simulation for Industrial Application: Fluid and Heat Transfer Analysis' has been successfully created. The portal is now accessible for participants to enroll and engage in the course content.\n\n Regards,\nTeam BD-SimuLab `,
        };

        try {
            const response = await fetch('https://bd-SIMULAB.onrender.com/userprofiledb', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(myNewProfile),
            });

            if (response.ok) {
                setIsProfileCreated(true);
                setCreatedProfile(myNewProfile);

                try {
                    const emailResponse = await axios.post('https://bd-SIMULAB.onrender.com/send-email', emailData);

                    if (emailResponse.data.success) {
                        // Handle success, maybe show a success message to the user
                    } else {
                        // Handle failure, show an error message to the user
                    }
                } catch (error) {
                    console.error('Error sending email:', error);
                    // Handle error, show an error message to the user
                }

                console.log('This is my new Profile', myNewProfile);
            } else {
                console.error('Failed to create profile.');
            }
        } catch (error) {
            console.error('Error creating profile:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                // Fetch data from enrollrequestdb
                const enrollResponse = await fetch('https://bd-SIMULAB.onrender.com/enrollrequestdb');
                const enrollData = await enrollResponse.json();
                const enrollResponseCA = await fetch('https://bd-SIMULAB.onrender.com/campusambassadors');
                const enrollDataCA = await enrollResponseCA.json();

                console.log('CA Data', enrollDataCA);

                // Check if the user's email exists in enrollrequestdb
                const isEnrolled = enrollData.some((user) => user.Email === firebaseUserEmail);
                const isEnrolledCA = enrollDataCA.some((user) => user.Email === firebaseUserEmail);

                console.log('This is enrolled student', isEnrolledCA);

                // Check if the user is found in enrollrequestdb
                let enrollStudent = null;

                if (isEnrolled) {
                    enrollStudent = enrollData.find((user) => user.Email === firebaseUserEmail);
                    setIsUserEnrolled(true);
                } else if (isEnrolledCA) {
                    enrollStudent = enrollDataCA.find((user) => user.Email === firebaseUserEmail);
                    setIsUserEnrolled(true);
                }

                setEnrolledStudentInfo(enrollStudent);

                console.log('This is Enrolled Student', enrollStudent);
                console.log('Here is enrolled Student info', enrolledStudentInfo);

                // setIsUserEnrolled(isEnrolled);

                console.log('Is user enrolled', isEnrolled);

                const profileResponse = await fetch('https://bd-SIMULAB.onrender.com/userprofiledb');
                const profileData = await profileResponse.json();

                // Check if the user's email exists in userprofiledb
                const userProfile = profileData.find((user) => user.Email === firebaseUserEmail);

                setCreatedProfile(userProfile);

                // If the user is enrolled, fetch data from userprofiledb
                if (isEnrolled) {
                    setIsProfileCreated(!!userProfile?.profileCreated);
                    setCreatedProfile(userProfile);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setIsLoading(false);
            }
        };

        if (firebaseUserEmail) {
            fetchData();
        }
    }, [firebaseUserEmail]);

    const handleGoToPortal = () => {
        history(`/bdsimulLab01/${createdProfile._id}`);
    };

    console.log('is Enrolled', isUserEnrolled);
    console.log('is profile Created', isProfileCreated);

    const handleDepartmnetChange = (e) => {
        const department = e.target.value;
        setDepartment(department);
        console.log('This is Department', department);
    };

    return (
        <div className="d-flex align-items-center justify-content-center mb-5" style={{ minHeight: '100vh' }}>
            <div style={{ color: 'white' }}>
                <h1>Enrollment</h1>
                <p>User Email: {firebaseUserEmail}</p>
                {isLoading ? (
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                ) : (
                    <>
                        {isUserEnrolled ? (
                            <>
                                {createdProfile?.Email ? (
                                    <>
                                        <p style={{ color: 'orange' }}>
                                            Your Enrollment is successful!
                                        </p>
                                        <Button variant="success" onClick={handleGoToPortal}>
                                            Go to Portal
                                        </Button>
                                    </>
                                ) : (
                                    <>
                                        <Form>
                                            <Form.Group className="mb-3" controlId="formName">
                                                <Form.Label>Name:</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter your name"
                                                    name="name"
                                                    readOnly
                                                    value={enrolledStudentInfo.FullName}
                                                />
                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId="formEmail">
                                                <Form.Label>Email:</Form.Label>
                                                <Form.Control
                                                    type="email"
                                                    placeholder="Enter your email"
                                                    name="email"
                                                    readOnly
                                                    value={enrolledStudentInfo.Email}
                                                />
                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId="formMobile">
                                                <Form.Label>Mobile:</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter your mobile number"
                                                    name="mobile"
                                                    readOnly
                                                    value={enrolledStudentInfo.MobileNumber}
                                                />
                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId="formDepartment">
                                                <Form.Label>Department:</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter your department"
                                                    name="department"
                                                    required
                                                    value={department}
                                                    onChange={(e) => setDepartment(e.target.value)}
                                                />
                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId="formUniversity">
                                                <Form.Label>University:</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter your university"
                                                    name="university"
                                                    required
                                                    value={University}
                                                    onChange={(e) => setUniversity(e.target.value)}
                                                />
                                            </Form.Group>
                                        </Form>
                                        <Button variant="primary" onClick={handleProfileCreation}>
                                            Create Profile
                                        </Button>
                                    </>
                                )}
                            </>
                        ) : (
                            // Render this when the user is not enrolled
                            <p>
                                You are not enrolled. Please contact BD-SimuLab System Administrator bdsimulab@gmail.com.
                            </p>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default SignInComponent;
