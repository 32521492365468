import React from 'react';
import { Container, Row, Col, Carousel } from 'react-bootstrap';
import './MultiCarousel.css'


import solidworksLogo from '../../../Utilities/Images/Solidworks-logo.jpg'
import solidworksSimuLogo from '../../../Utilities/Images/solidworks-simulation.png';
import ANSYSpceClaimLogo from '../../../Utilities/Images/ansys-spaceclaim-logo.jpg';
import ANSYSFluentLogo from '../../../Utilities/Images/Ansys-Fluent.jpeg';
import AutodeskCFDLogo from '../../../Utilities/Images/AutodeskCFD.png';
import MATLABLogo from '../../../Utilities/Images/MATLAB-logo.png';
import MATLABsIMULINK from '../../../Utilities/Images/MATLAB-Simulink.webp';
import python from '../../../Utilities/Images/python-logo.png';

const images = [

  `${solidworksSimuLogo}`,
  `${ANSYSpceClaimLogo}`,
  `${ANSYSFluentLogo}`,
  `${AutodeskCFDLogo}`,
  `${python}`,
  `${MATLABLogo}`,
  `${MATLABsIMULINK}`,
  `${solidworksSimuLogo}`,
  `${ANSYSpceClaimLogo}`,
  `${ANSYSFluentLogo}`,
  `${AutodeskCFDLogo}`,
  `${python}`,



];

const chunk = (arr, size) => {
  return arr.reduce((chunks, el, i) => (i % size ? chunks[chunks.length - 1].push(el) : chunks.push([el])) && chunks, []);
};

const MultiCarousel = () => {
  const imagesChunked = chunk(images, 6);

  return (
    <Container>
      <Row>
        <Col className='carouselItems'>
          <Carousel>
            {imagesChunked.map((imageGroup, index) => (
              <Carousel.Item key={index}>
                <Row>
                  {imageGroup.map((image, index) => (
                    <Col key={index} xs={6} sm={4} md={2} className='carouselItem'>
                      <img src={image} alt="" className="img-fluid" />
                    </Col>
                  ))}
                </Row>
              </Carousel.Item>
            ))}
          </Carousel>
        </Col>
      </Row>
    </Container>
  );
};

export default MultiCarousel;
