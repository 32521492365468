import React, { useContext, useState } from 'react';
import { useEffect } from 'react';
import { Button, Col, Form, FormGroup, Modal, Row } from 'react-bootstrap';
import { FaPlusSquare } from 'react-icons/fa'
import useAuth from '../hooks/useAuth';
import useFirebase from '../hooks/useFirebase';
import { userContext } from '../hooks/UseContextProvider';

const ModalforEdit = () => {
  const [show, setShow] = useState(false);
  const [userProfile, setUserProfile] = useState({});

  const { currentUser } = useContext(userContext);


  console.log('Hello from current usder', currentUser)

  const [profile, setProfile] = useState({});
  const [currentUserPro, setCurrentUserPro] = useState('');

  const [currentUserId, setCurrentUserId] = useState('');
  const [updatedStreet, setUpdatedStreet] = useState('');
  const [updatedCity, setUpdatedCity] = useState('');
  const [updatedCountry, setUpdatedCountry] = useState('');
  const [refreshKey, setRefreshKey] = useState(0);


  const handleShow = () => setShow(true);
  const auth = useAuth();

  const firebaseData = useFirebase();
  const FirebaseuserEmail = firebaseData.user.email;



  const handleStreetChange = (e) => {
    // console.log(e.target.value)

    const updatedStreet = e.target.value;

    console.log('This is Updated NAME', updatedStreet)
    const updatedProfile = { ...profile };
    profile.street = updatedStreet;
    setUpdatedStreet(updatedStreet)
    setUserProfile(updatedProfile);
  }
  const handleCityChange = (e) => {
    // console.log(e.target.value)

    const updatedCity = e.target.value;

    console.log('This is UpdatedCity', updatedCity)
    const updatedProfile = { ...profile };
    profile.city = updatedCity;
    setUpdatedCity(updatedCity)
    setUserProfile(updatedProfile);

    console.log('This is Updated City', updatedCity);


  }
  const handleCountryChange = (e) => {
    // console.log(e.target.value)

    const updatedCountry = e.target.value;

    console.log('This is UpdatedCity', updatedCity)
    const updatedProfile = { ...profile };
    profile.country = updatedCountry;
    setUpdatedCountry(updatedCountry)
    setUserProfile(updatedProfile);

    console.log('This is Updated country', updatedCountry);


  }

  // console.log('This is myCurrent  User from user Context', currentUser)

  useEffect(() => {
    // const currentuserID = toString(currentUser._id)
    // const url = `http://localhost:5000/userprofiledb/6460d77a621f8de0e273ec5b`
    // fetch(url)
    //   .then(res => res.json())
    //   .then(data => {
    //     // console.log('Here is Userprofile Database', data)
    //     // setUserProfile(data);
    //     setProfile(data)

    //     // console.log('This is user profile in Modal link', userProfile)
    //     // console.log('This is Address street', userProfile.data.street)

    //   })

    setProfile(currentUser)




  }, [currentUser]);

  const handleClose = () => {
    setShow(false)
  }

  const handleUpdateUser = (e) => {
    if (updatedStreet) {
      userProfile.street = updatedStreet;
    }
    if (updatedCity) {
      userProfile.city = updatedCity;
    }
    if (updatedCountry) {
      userProfile.country = updatedCountry;
    }

    const updateUser = userProfile
    const url = `http://localhost:5000/userprofiledb/${currentUser._id}`

    fetch(url, {
      method: 'PUT',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify(updateUser)
    })
      .then(res => res.json())
      .then(data => {
        // console.log('Here is Userprofile Database', data)
        // setUserProfile(data);
        // console.log('This is user data from Updated User', data.acknowledged)
        if (data.acknowledged === true) {
          alert('Updated user successfully')
        }

        // console.log('This is user profile in Modal link', userProfile)
        // console.log('This is Address street', userProfile.data.street)

      })



    // e.preventDefault();


    window.location.reload();

    setShow(false)
  };


  // console.log('This is user profile from last', profile.street)
  // console.log('This is updated street from last', updatedStreet)
  // console.log('Current user found in Modal', currentUser._id)
  return (
    <div>
      <Form >
        <Button variant="default" onClick={handleShow}>
          <FaPlusSquare></FaPlusSquare> Edit Info
        </Button>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Modal heading</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <FormGroup>
              <Form.Label htmlFor="address">Address</Form.Label>
              <Row>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Street</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter your street"
                      value={profile.street || ''}
                      onChange={handleStreetChange}

                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>City</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter your city"
                      value={profile.city || ''}
                      onChange={handleCityChange}

                    />
                  </Form.Group>
                </Col>
              </Row>

              <Form.Group>
                <Form.Label>Country*</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your country"
                  value={profile.country || ''}
                  onChange={handleCountryChange}

                />
              </Form.Group>



            </FormGroup>

          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleUpdateUser} >
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      </Form>
    </div>
  );
};

export default ModalforEdit;