import React, { useState, useEffect, useRef, useContext } from 'react';
import { Container } from 'react-bootstrap';
import './Videoplayer.css';
import { userContext } from '../../hooks/UseContextProvider';

const Videoplayer = (props) => {
    const [width, setWidth] = useState('100%');
    const [username, setUsername] = useState('Enroll Now');
    const [played, setPlayed] = useState(0);
    const [playedSeconds, setPlayedSeconds] = useState(0);
    const [duration, setDuration] = useState(0);
    const playerRef = useRef(null);

    const { currentUser, usersData, usersProfile } = useContext(userContext);




    useEffect(() => {
        // Implement interval logic for flipping the username here
        const intervalId = setInterval(() => {
            const reversedUsername = username.split('').reverse().join('');
            setUsername(reversedUsername);
        }, 5000);

        return () => clearInterval(intervalId);
    }, [username]);

    useEffect(() => {
        // Implement window resize logic here
        const handleResize = () => {
            // Adjust width based on window size
            if (window.innerWidth > 768) {
                setWidth('50%');
            } else if (window.innerWidth < 768) {
                setWidth('30%');
            } else {
                setWidth('50%');
            }
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);



    return (
        <Container className="video-player-container">
            {/* YouTube iframe */}
            <div className="video-container" style={{ position: 'relative', width: '100%', paddingBottom: '56.2500%', boxShadow: '0 2px 8px 0 rgba(63,69,81,0.16)', marginTop: '1.6em', marginBottom: '0.9em', overflow: 'hidden', borderRadius: '8px', willChange: 'transform' }}>
                <div>
                    <iframe
                        loading="lazy"
                        className="video"
                        style={{ position: 'absolute', width: '80%', height: '80%', top: 0, left: 0, border: 'none', padding: 0, margin: 0 }}
                        src={props.url}
                        allowFullScreen
                        allow="fullscreen"

                    ></iframe>
                    <div className="floating-username">{currentUser.Email}</div>
                </div>
            </div>
            {/* Overlay div to prevent right-click events and disable interaction */}
            <div className="overlay" onContextMenu={(e) => e.preventDefault()}>
                {/* Add your overlay content or hiding corner if needed */}
                <div className="hiding-corner"></div>
            </div>


        </Container>
    );
};

export default Videoplayer;
