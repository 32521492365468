
import React, { useState } from 'react';
import { Button, Card, Form, InputGroup, Modal, OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap';

import './CourseCard.css';
import { useEffect } from 'react';
import emailjs from '@emailjs/browser';
import axios from 'axios';



const CourseCard = () => {

    const [show, setShow] = useState(false);

    const [PromoCode, setPromocode] = useState('');
    const [PromoCodeF, setPromocodeF] = useState('');
    const [ApplyPromoCode, setApplyPromoCode] = useState('Please write your promo code (if any)');

    const [showSuccessModal, setShowSuccessModal] = useState(false);

    const [Submit, setSubmit] = useState('');

    const [netPayment, setNetPayment] = useState('5000');

    const [MobileNumber, setMobileNumber] = useState('');
    const [FullName, setFullName] = useState('');
    const [Email, setEmail] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [enrollRequests, setEnrollRequests] = useState([]);
    const handleClose = () => {
        setShowSuccessModal(false);
        setShow(false);

    }
    const handleShow = () => setShow(true);

    useEffect(() => {
        // Fetch existing enroll requests from the server
        fetch('https://bd-SIMULAB.onrender.com/enrollrequestdb')
            .then((response) => response.json())
            .then((data) => {
                setEnrollRequests(data);
            })
            .catch((error) => {
                console.error(error);
            });
    }, [Email]);




    const handlePromoCode = (event) => {
        console.log('Promo code clicked');
        setApplyPromoCode('');
        setPromocode('');

        const PromoCodes = [
            'SIMULABSA01',
            'SIMULABNS01',
            'SIMULABOR01',
            'SIMULABTA01',
            'SIMULABMN01',
            'SIMULABNI01',
            'SIMULABB07',
            'SIMULABMA01',
            'SIMULABAM01',
            'SIMULABMC01',
            'SIMULABAH01',
            'SIMULABZD01',
            'SIMULAB1',
            'SIMULABS1',
            'SIMULABDUET'
        ];

        const specificPromoCodes = {
            'SIMULAB1': 4500,
            'SIMULABS1': 4000,
            'SIMULABOR01': 3500,
            'SIMULABDUET': 3500,
        };

        const promoCodeValue = specificPromoCodes[PromoCode];

        if (promoCodeValue) {
            setApplyPromoCode('Promocode successfully applied!');
            setNetPayment(promoCodeValue);
        } else if (PromoCodes.includes(PromoCode)) {
            setApplyPromoCode('Promocode successfully applied!');
            setNetPayment(4000);
        }
        else {
            setApplyPromoCode(`Sorry! False Promo code.`);
        }
        // Handle case when the promo code is incorrect
    };




    const handlePromoCodeChange = (event) => {
        // Update the Promocode state when input changes
        setPromocode(event.target.value);
        setPromocodeF(PromoCode)



    };
    const handleEmailChange = (event) => {
        // Update the Promocode state when input changes
        setEmail(event.target.value);



    };
    const handleNameChange = (event) => {
        // Update the Promocode state when input changes
        setFullName(event.target.value);



    };
    const handleMobleNumberChange = (event) => {
        // Update the Promocode state when input changes
        setMobileNumber(event.target.value);



    };


    const handleSubmitErollRequest = async () => {
        setIsSubmitting(true);

        const payment = 'No';

        if (FullName === '' || Email === '' || MobileNumber === '') {
            setSubmit('*Fields are mandatory');
        } else if (enrollRequests.some((request) => request.Email === Email)) {
            setSubmit('You have already submitted an Enrollment Request. Now you can close the window. We will contact you at the right time and share Payment details');
        } else {
            setSubmit('');

            const newEnrollRequest = { FullName, Email, MobileNumber, netPayment, PromoCodeF, payment };

            const emailData = {
                to: Email, // Replace with the recipient's email address
                subject: 'Enrollment Request Confirmation',
                message: `Dear ${FullName}, \nWe trust this email finds you well. \nWe acknowledge receipt of your enrollment request from the email address ${Email} for the Course 'Simulation for Industrial Applicaation: Fluid and Heat Transfer Analysis' at BD-SimuLab. We appreciate your interest in our program.\nPlease be informed that your course fee is 1000 Taka. At the appropriate time, we will contact you to provide further details regarding payment procedures and additional information pertinent to your course. 
                \n Additionally, we have created a Private Facebook group exclusively for first batch. This group will serve as a platform for discussions, Q&A sessions, and a sense of community among participants.
                You can join the group by clicking https://fb.me/g/p_5xE7iMy3KJ3RrNAS/k8YC8rPU
                \n we invite you to join us for an online orientation meeting on 6th January 2024, Saturday, at 9:00 pm. During this session, we will guide you through the process of accessing our online portal and course materials.
                Meeting Link : https://meet.google.com/vmm-bhgw-zri
                 Date: 6th January 2024, Saturday
               Time:9:00 PM (BD Time)
                \nWe extend our gratitude for choosing BD-SimuLab, and we look forward to assisting you throughout your learning journey.
                \nShould you have any immediate queries or concerns, please feel free to contact us. \nFor faster contact you can message us via WhatsApp at +491629210451.
                \n\n Regards,\nTeam BD-SimuLab 
                visit https://www.bdsimulab.com
                 `,
            };

            try {
                const enrollResponse = await fetch('https://bd-SIMULAB.onrender.com/enrollrequestdb', {



                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(newEnrollRequest),
                });

                const enrollData = await enrollResponse.json();

                if (enrollData.insertedId) {
                    setShowSuccessModal(true);
                    setIsSubmitting(false);

                    // Send confirmation email using Axios
                    try {
                        const emailResponse = await axios.post('https://bd-SIMULAB.onrender.com/send-email', emailData);

                        if (emailResponse.data.success) {
                            // Handle success, maybe show a success message to the user
                        } else {
                            // Handle failure, show an error message to the user
                        }
                    } catch (error) {
                        console.error('Error sending email:', error);
                        // Handle error, show an error message to the user
                    }
                }
            } catch (error) {
                console.error(error);
            }

            setPromocode('');
            setEmail('');
            setFullName('');
            setMobileNumber('');
            setNetPayment(5000);
        }
    };



    return (

        <Card style={{ width: '18rem', border: '2px solid cyan', backgroundColor: ' rgba(255, 255, 255, 0.047)', color: 'white', boxShadow: '0 2px 10px white' }} className='Course-card' >
            <Card.Img variant="top" src="Airfoil.png" />
            <Card.Body>
                <Card.Title>Mastering Engineering Computation: Fluid and Heat Transfer Analysis</Card.Title>
                <Card.Text>
                    Starting from 15th January
                    <br></br>13 Weeks;
                    Fee: --- BDT
                    <br />

                </Card.Text>

                {/* <Button variant="outline-success">Enroll Now</Button> */}

                <Button variant="outline-primary" className='mt-3' onClick={handleShow}>
                    Request to Enroll
                </Button>

                <Modal
                    show={show}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}

                    contentClassName='EnrollModal'
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Mastering Engineering Computation: Fluid and Heat Transfer analysis: Enrollment Request</Modal.Title>
                    </Modal.Header>
                    <Modal.Body >
                        <p style={{ color: 'orange' }}> The Enrollment is currently closed.</p>

                        <InputGroup className="mb-3" >
                            <InputGroup.Text id="basic-addon1" required>Full Name*</InputGroup.Text>
                            <Form.Control
                                placeholder="Write your full name here"
                                aria-label="Username"
                                aria-describedby="basic-addon1"
                                required
                                value={FullName}
                                onChange={handleNameChange}

                            />
                        </InputGroup>

                        <InputGroup className="mb-3">
                            <InputGroup.Text id="basic-addon1">Email*</InputGroup.Text>
                            <Form.Control
                                placeholder="Email"
                                aria-label="Username"
                                aria-describedby="basic-addon1"
                                required
                                value={Email}
                                onChange={handleEmailChange}
                            />
                        </InputGroup>

                        {/* <Form.Label htmlFor="basic-url">Mobile Number</Form.Label> */}
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="basic-addon3">
                                Mobile Number*
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip id={`tooltip-top`}>
                                            Please write the country code if you enroll from Abroad
                                        </Tooltip>
                                    }
                                >
                                    <span className="info-icon">ℹ️</span>
                                </OverlayTrigger>
                            </InputGroup.Text>
                            <Form.Control
                                placeholder='Mobile'
                                id="basic-url"
                                value={MobileNumber}
                                onChange={handleMobleNumberChange}
                                aria-describedby="basic-addon3" />


                        </InputGroup>

                        <InputGroup className="mb-3">
                            <InputGroup.Text id="basic-addon3">
                                Course Fee
                            </InputGroup.Text>
                            <Form.Control id="basic-url" aria-describedby="basic-addon3"
                                placeholder='5000/- BDT' readOnly />
                        </InputGroup>


                        <InputGroup className="mb-3">
                            <Form.Control
                                placeholder="write your promo code here"
                                aria-label="Recipient's username"
                                aria-describedby="basic-addon4"
                                value={PromoCode} // Bind input value to the state
                                onChange={handlePromoCodeChange} // Update state on input change
                            />
                            <InputGroup.Text id="basic-addon2"><Button variant='outline-danger' onClick={handlePromoCode}>Appply Promo Code</Button></InputGroup.Text>
                        </InputGroup>
                        <InputGroup>
                            <InputGroup.Text><span style={{ color: 'blue', fontWeight: 'bold' }}>Net Payment: {netPayment}/- BDT </span>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip id={`tooltip-top`}>
                                            This is the net ammount that you have to pay.
                                        </Tooltip>
                                    }
                                >
                                    <span className="info-icon">ℹ️</span>
                                </OverlayTrigger>


                            </InputGroup.Text>
                            <Form.Control style={{ color: 'green' }} as="textarea" readOnly aria-label="With textarea" value={ApplyPromoCode} />
                        </InputGroup>
                        <p style={{ color: 'goldenrod' }}>{Submit}</p>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="outline-primary" type="submit" >Submit</Button>
                    </Modal.Footer>
                </Modal>


                <Modal show={isSubmitting} onHide={handleClose} backdrop="static" keyboard={false} contentClassName="enrollCard-succcess-modal">
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <span style={{ color: 'green', fontWeight: 'bold' }}>BD</span>
                            <span style={{ color: 'Orange', fontWeight: 'bold' }}>-SIMULAB</span>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="d-flex justify-content-center align-items-center">
                        <Spinner animation="border" role="status">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    </Modal.Body>
                </Modal>

                <Modal show={showSuccessModal} onHide={handleClose} contentClassName="enrollCard-succcess-modal">
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <span style={{ color: 'green', fontWeight: 'bold' }}>BD</span>
                            <span style={{ color: 'Orange', fontWeight: 'bold' }}>-SIMULAB</span>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ color: 'white' }}>
                        Your request has been successfully submitted. A confirmation email will be sent to your email address.
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>Close</Button>
                    </Modal.Footer>
                </Modal>



            </Card.Body>
        </Card>



    );
};

export default CourseCard;