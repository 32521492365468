import React from 'react';

import './Coursedetails.css';
import { Badge, Button, Col, Container, Figure, Image, ListGroup, Row } from 'react-bootstrap';
import courseImage from '../../../Utilities/Images/1.PNG'
import courseImage2 from '../../../Utilities/Images/2.PNG'
import courseImage3 from '../../../Utilities/Images/3.PNG'
import { useState } from 'react';
import { Link } from 'react-router-dom';

const Coursedetails = () => {
    const [file, setFile] = useState(null);

    const pdfFileName = 'BD-SimuLab Brochur.pdf';


    return (
        <Container fluid>
            <Row>

                <Col lg={4}>

                    <Row>
                        <Figure>
                            <Figure.Image
                                width={171}
                                height={180}
                                alt="171x180"
                                src={courseImage2}
                            />
                            <Figure.Caption>

                                <a href={process.env.PUBLIC_URL + '/' + pdfFileName} download>
                                    <Button variant='outline-success'>Download Brochure</Button>
                                </a>
                            </Figure.Caption>
                        </Figure>


                    </Row>
                    <h3>Course Title: Simulation for Industrial Application: Fluid and Thermal analysis</h3>
                    <h5>Batch: 01</h5>
                    <p className="course-Description">
                        This course is designed to provide students with a comprehensive understanding of Computational Fluid
                        Dynamics (CFD) and its practical applications in various industrial sectors, Thermal and Fluid system
                        Design and its Practical application. The course will cover the fundamental theoretical concepts of fluid
                        dynamics, numerical methods, and simulation techniques, and how they are applied to solve real-world
                        industrial problems. Through a combination of theoretical knowledge and hands-on practice using CFD
                        software, students will learn to analyze, predict, and optimize fluid flow behavior in industrial processes.
                        The system design for Fluid and thermal structure design will be covered with MATLAB SimuLik/SimScap.

                    </p>

                    <h5>Do you like to know more?</h5>

                    <Link to='/coursedetails/moredetails'><Button variant='outline-secondary'>Click here</Button></Link>
                </Col>
                <Col lg={4} >
                    <Row className='brochure-div'>
                        <Image src={courseImage} className='Brochure-image'>

                        </Image>
                        <Image src={courseImage2} className='Brochure-image'>

                        </Image>
                        <Image src={courseImage3 } className='Brochure-image'>

                        </Image>
                    </Row>

                </Col>
                <Col lg={4} className='course-overview'>
                    <Row>
                        <h4>Good to know</h4>

                        <div>
                            <ListGroup as="ol" numbered>
                                <ListGroup.Item
                                    as="li"
                                    className="d-flex justify-content-between align-items-start"
                                >
                                    <div className="ms-2 me-auto">
                                        <div className="fw-bold">Recorded Videos</div>
                                        Learn at your own pace and on your schedule, accommodating busy lifestyles and varying learning speeds
                                    </div>

                                </ListGroup.Item>
                                <ListGroup.Item
                                    as="li"
                                    className="d-flex justify-content-between align-items-start"
                                >
                                    <div className="ms-2 me-auto">
                                        <div className="fw-bold">Interactive Session</div>
                                        Participate in weekly live interactive sessions to get your questions answered and gain a clearer understanding of the course material
                                    </div>

                                </ListGroup.Item>
                                <ListGroup.Item
                                    as="li"
                                    className="d-flex justify-content-between align-items-start"
                                >
                                    <div className="ms-2 me-auto">
                                        <div className="fw-bold">Bonus Program</div>
                                        Stay on track and enhance your skills further by enrolling in our free bonus program. Just ensure you've completed your course within the timeline and get ready to take your skill to the next level.
                                    </div>

                                </ListGroup.Item>
                            </ListGroup>
                        </div>
                        <div style={{ marginTop: '20px' }}>
                            <h4>For your information</h4>
                            <ListGroup as="ol" numbered className="fw-b">
                                <ListGroup.Item variant="success" as="li" style={{ fontWeight: 'bold' }}>Course Duration: 12 weeks</ListGroup.Item>
                                <ListGroup.Item as="li" variant="info" style={{ fontWeight: 'bold' }}>Video Modules: New Modules released on Monday, Tuesday, and Wednesday</ListGroup.Item>
                                <ListGroup.Item as="li" variant="warning" style={{ fontWeight: 'bold' }}>Fee: 5000/- BDT <Button variant="danger" className='m-1'>Enroll Now</Button></ListGroup.Item>
                            </ListGroup>
                        </div>
                    </Row>

                </Col>
            </Row>

        </Container>
    );
};

export default Coursedetails;