import React from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import img1 from '../../Images/thankyou.jpg'

import './Regsuccess.css'
const RegSuccess = () => {
    return (
        <Container className="regSuccess" >
           <Row className="Success">
           <h4>Congratulation! Thank you for your Registration</h4>
            <Link to='/home'>Please go back to Home</Link>

            {/* <Image width="50%" src={img1}></Image> */}
           </Row>
        </Container>
    );
};

export default RegSuccess;