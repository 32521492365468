import React, { useContext, useState } from 'react';
import { useEffect } from 'react';
import { Button, Col, Form, FormGroup, Modal, Row } from 'react-bootstrap';
import { FaPlusSquare } from 'react-icons/fa'
import useAuth from '../hooks/useAuth';
import useFirebase from '../hooks/useFirebase';
import { userContext } from '../hooks/UseContextProvider';

const ModalIntro = () => {
    const [show, setShow] = useState(false);
    const [userProfile, setUserProfile] = useState({});

    const { currentUser } = useContext(userContext);


    console.log('Hello from current usder', currentUser)

    const [profile, setProfile] = useState({});
    const [currentUserPro, setCurrentUserPro] = useState('');

    const [currentUserId, setCurrentUserId] = useState('');
    const [updatedIntro, setupdatedIntro] = useState('');
    const [updatedDegree, setupdatedDegree] = useState('');
    const [updatedCountry, setUpdatedCountry] = useState('');
    const [refreshKey, setRefreshKey] = useState(0);


    const handleShow = () => setShow(true);
    const auth = useAuth();

    const firebaseData = useFirebase();
    const FirebaseuserEmail = firebaseData.user.email;



    const handleIntroChange = (e) => {
        // console.log(e.target.value)

        const updatedIntro = e.target.value;

        console.log('This is Updated NAME', updatedIntro)
        const updatedProfile = { ...profile };
        profile.intro = updatedIntro;
        setupdatedIntro(updatedIntro)
        setUserProfile(updatedProfile);




    }
    const handleDegreeChange = (e) => {
        // console.log(e.target.value)

        const updatedDegree = e.target.value;

        console.log('This is updatedDegree', updatedDegree)
        const updatedProfile = { ...profile };
        profile.degree = updatedDegree;
        setupdatedDegree(updatedDegree)
        setUserProfile(updatedProfile);

        console.log('This is Updated City', updatedDegree);


    }
    const handleCountryChange = (e) => {
        // console.log(e.target.value)

        const updatedCountry = e.target.value;

        console.log('This is updatedDegree', updatedDegree)
        const updatedProfile = { ...profile };
        profile.country = updatedCountry;
        setUpdatedCountry(updatedCountry)
        setUserProfile(updatedProfile);

        console.log('This is Updated country', updatedCountry);


    }

    // console.log('This is myCurrent  User from user Context', currentUser)

    useEffect(() => {
        setProfile(currentUser)



    }, [currentUser]);

    const handleClose = () => {
        setShow(false)
    }

    const handleUpdateUser = (e) => {
        if (updatedIntro) {
            userProfile.intro = updatedIntro;
        }


        const updateUser = userProfile;
        const url = `https://bd-simulab.onrender.com/userprofiledb/${currentUser._id}`

        fetch(url, {
            method: 'PUT',
            headers: { 'content-type': 'application/json' },
            body: JSON.stringify(updateUser)
        })
            .then(res => res.json())
            .then(data => {
                // console.log('Here is Userprofile Database', data)
                // setUserProfile(data);
                console.log('This is user data from Updated User', data.acknowledged)
                if (data.acknowledged === true) {
                    alert('Updated user successfully')
                }

                // console.log('This is user profile in Modal link', userProfile)
                // console.log('This is Address street', userProfile.data.street)

            })



        // e.preventDefault();


        window.location.reload();

        setShow(false)
    };


    // console.log('This is user profile from last', profile.street)
    // console.log('This is updated street from last', updatedIntro)

    return (
        <div>
            <Form >
                <Button variant="default" onClick={handleShow}>
                    <FaPlusSquare></FaPlusSquare> Edit Info
                </Button>

                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Modal heading</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <FormGroup>
                            <Form.Label htmlFor="intro">Edit your Intro</Form.Label>
                            <Row>
                                <Col md={6}>
                                    <Form.Group>
                                        <Form.Label>Intro</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter your street"
                                            value={profile.intro || ''}
                                            onChange={handleIntroChange}

                                        />
                                    </Form.Group>
                                </Col>

                            </Row>




                        </FormGroup>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={handleUpdateUser}>
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Form>
        </div>
    );
};

export default ModalIntro;