import React, { useContext } from 'react';
import NestedListGroup from './NestedListGroup';
import './fetchNodes'

import fetchNodes from "./fetchNodes";
import { userContext } from '../../hooks/UseContextProvider';

// const nodes = fetchNodes();
const TestList = () => {
    const { playlist } = useContext(userContext);
  console.log('This is from TestList', playlist)
    return (
        <div>
            <NestedListGroup playlist={playlist}></NestedListGroup>
        </div>
    );
};

export default TestList;