// import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Header from './Components/Header/Header';
import Banner from './Components/Banner/Banner';
import Home from './Components/Home/Home';
import Doctors from './Components/Events/Events';
import { useState, createContext } from 'react';
import { useEffect } from 'react';
import Services from './Components/Services/Services';
import Login from './Components/Login/Login';
import Register from './Components/Register/Register';
import UseContextProvider, { userContext } from './Components/hooks/UseContextProvider';
import PrivateRoute from './Components/hooks/PrivateRoute';
import useFirebase from './Components/hooks/useFirebase';
import PrivateRouteExtra from './Components/hooks/PrivateRouteExtra';

import NotFound from './Components/NotFound/NotFound';
import RegSuccess from './Components/RegSuccess/RegSuccess';
import ServiceDetails from './Components/ServiceDetails/ServiceDetails';
import { Col, Container, Row } from 'react-bootstrap';


import NewHeader from './Components/newNav/NewHeader';
import Subheader from './Components/SubHeader/Subheader';
import Regform from './Components/Regform/Regform';
import MECourses from './Components/Courses/MECourses/MECourses';
import Events from './Components/Events/Events';
import Footer from './Components/Footer/Footer';
import UserProfile from './Components/UserProfile/UserProfile';
import UpdateUser from './Components/UserProfile/UpdateUser/UpdateUser';
import DetailsProfile from './Components/UserProfile/UpdateUser/DetailsProfile/DetailsProfile';
import NewUserForm from './Components/UserProfile/NewUserForm/NewUserForm';
import SignUp from './Components/SignUp/SignUp';
import SignIn from './Components/SignIn/SignIn';
import SignUpForm from './Components/SignUpForm/SignUpForm';
import Blogs from './Components/Blogs/Blogs';
import ResetPassword from './Components/SignIn/ResetPassword';
import About from './Components/About/About';
import CourseEnroll from './Components/Courses/Enroll/CourseEnroll';
import Coursedetails from './Components/Courses/Coursedetails/Coursedetails';
import Moredetails from './Components/Courses/Coursedetails/Moredetails';
import Coursedescription from './Components/Courses/Coursedetails/Coursedescription';
import CAmbassedor from './Components/CAProgram/CAmbassedor';
import RegistrationFormCA from './Components/CAProgram/CAreg/RegistrationFormCA';
import { ContactUs } from './Components/Courses/Coursedetails/emailTest';
import ExportToExcel from './Components/Courses/ExportToExcel/ExportToExcel';
import SignInComponent from './Components/SignInComponent/SignInComponent';
import PrivateRouterFB from './Components/UserProfile/UpdateUser/EnrollFirstBatchProfile/PrivateRouterFB';
import EnrollFirstBatchProfile from './Components/UserProfile/UpdateUser/EnrollFirstBatchProfile/EnrollFirstBatchProfile';
import PrivateRouteFB from './Components/UserProfile/UpdateUser/EnrollFirstBatchProfile/PrivateRouterFB';
import EmailSender from './Components/SignInComponent/EmailSender';
import YouTubePlayer from './Components/Courses/VideoPlayer/YouTubePlayer';

// import UpdateUser from './Components/UserProfile/UpdateUser/UpdateUser';
// import UpdateUser from './Components/UserProfile/UpdateUser/UpdateUser';

// import '../src/Components/hooks/useContextProvider'


// export const UserContext = createContext('user');
function App() {




  return (
    <UseContextProvider>
      <BrowserRouter>


        <Container fluid className="app-header">


          <Row className="header-row">
            <Col lg={12}>
              <Header></Header>
            </Col>
          </Row>
        </Container>

        <Container fluid className="Routes-div">
          <Row>
            <Col lg={12}>

              <Row>
                <Routes>

                  <Route path="/" element={<Home></Home>}></Route>
                  <Route path="/home" element={<Home></Home>}></Route>
                  <Route path="/email" element={<ContactUs></ContactUs>}></Route>
                  <Route path="/exportreq" element={<ExportToExcel></ExportToExcel>}></Route>




                  <Route path="/regSuccess" element={<RegSuccess></RegSuccess>}></Route>
                  <Route path="/emailsender" element={<EmailSender></EmailSender>}></Route>






                  <Route path="/doctor/reg" element={<RegSuccess></RegSuccess>}></Route>

                  {/* <Route path="/doctor" element={<Doctors></Doctors>}> </Route> */}
                  {/* <Route path="/doctor/:doctorId" element={<Doctors></Doctors>}     ></Route> */}



                  {/* <Route path="/services" element={<Services></Services>}></Route> */}
                  <Route path="/login" element={<Login></Login>}></Route>
                  <Route path="/careg" element={<RegistrationFormCA></RegistrationFormCA>}></Route>



                  <Route path="/signup" element={<SignUp></SignUp>}></Route>
                  <Route path="/bdsimulLab01" element={<SignInComponent></SignInComponent>}></Route>

                  {/* <PrivateRouterFB path="/profile" component={DetailsProfile} /> */}


                  <Route path="/signin" element={<SignIn></SignIn>}></Route>
                  <Route path="/about" element={<About></About>}></Route>
                  <Route path="/enroll" element={<CourseEnroll></CourseEnroll>}></Route>
                  <Route path="/coursedescription/coursedetails" element={<Coursedetails></Coursedetails>}></Route>
                  <Route path="/coursedetails/moredetails" element={<Moredetails></Moredetails>}></Route>

                  <Route path="/coursedescription" element={<Coursedescription></Coursedescription>}></Route>
                  {/* <Route path="/" element={<CAmbassedor></CAmbassedor>}></Route>*/}




                  <Route path="/resetpassword" element={<ResetPassword></ResetPassword>}></Route>

                  <Route path="/signupform" element={<SignUpForm></SignUpForm>}></Route>
                  <Route path="/blogs" element={<Blogs></Blogs>}></Route>
                  <Route path="/blogs/:id" element={<Blogs></Blogs>}></Route>




                  <Route path="/userprofile" element={<UserProfile></UserProfile>}></Route>
                  <Route path="/profile" element={<DetailsProfile></DetailsProfile>}></Route>
                  <Route path="/updateuser/:id" element={<UpdateUser></UpdateUser>}></Route>


                  <Route path="/userForm" element={<NewUserForm></NewUserForm>}></Route>
                  <Route path="/youtube" element={<YouTubePlayer></YouTubePlayer>}></Route>



                  <Route path="/register" element={<Register></Register>}></Route>
                  {/* <Route path="/blog" element={<Services></Services>}></Route> */}
                  {/* <Route path="/regform" element={<Regform></Regform>}></Route> */}
                  <Route path="/mecourse" element={<MECourses></MECourses>}></Route>
                  <Route path="/events" element={<Events></Events>}></Route>


                  <Route element={<PrivateRoute />}>
                    {/* <Route element={<Home/>} path="/" exact/> */}
                    <Route element={<CourseEnroll />} path="/enrollment" />
                    <Route element={<UpdateUser />} path="/checkenrollment" />
                  </Route>
                  <Route element={<PrivateRouteFB />}>
                    {/* <Route element={<Home/>} path="/" exact/> */}
                    <Route element={<EnrollFirstBatchProfile></EnrollFirstBatchProfile>} path="/bdsimulLab01/:id" />
                    <Route element={<MECourses></MECourses>} path="/bdsimulLab01/:id/mycourse" />
                    <Route element={<MECourses></MECourses>} path="/bdsimulLab01/:id/mycourse/:label" />

                  </Route>
                  {/* <Route path="/bdsimulLab01/:id/*" element={<PrivateRouteFB />}>
                    <Route index element={<EnrollFirstBatchProfile />} />
                  </Route> */}




                  {/* <Route
                    path="/simulabbatch01/:id"
                    element={<PrivateRouterFB>
                      <EnrollFirstBatchProfile></EnrollFirstBatchProfile>
                    </PrivateRouterFB>}
                  ></Route> */}

                  {/* <PrivateRouterFB path="/simulabbatch01/:id" component={<EnrollFirstBatchProfile></EnrollFirstBatchProfile>} /> */}


                  <Route path="*" element={<NotFound></NotFound>}></Route>


                </Routes>
              </Row>
            </Col>
          </Row>




        </Container>


        <Container fluid className="footer-app">
          <Row >
            <Col lg={12}>
              <Footer></Footer>
            </Col>
          </Row>
        </Container>



        {/* <Container fluid className='footer-app'>
          <Col>

            <Row>
              <h1>This is Footer</h1>
            </Row>
          </Col>
        </Container> */}

      </BrowserRouter>



    </UseContextProvider>

  );
}

export default App;
