import { useState } from "react";
import { useNavigate } from "react-router-dom";
// import firebase from "firebase/app";
import "firebase/auth";
import { Button, Form, FormGroup, FormText } from 'react-bootstrap';
import firebaseConfig from "../hooks/firebase.config";
import useFirebase from "../hooks/useFirebase";
import { createUserWithEmailAndPassword, getAuth, sendEmailVerification } from "firebase/auth";

const SignupForm = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        email: "",
        password: "",
        confirmPassword: "",
        acceptedTerms: false,
    });
    const [passwordShown, setPasswordShown] = useState(false);
    const [passwordConfirmationShown, setPasswordConfirmationShown] = useState(false);
    const [error, setError] = useState("");
    const auth = getAuth();

    const handleInputChange = (event) => {
        const { name, value, type, checked } = event.target;

        setFormData({
            ...formData,
            [name]: type === "checkbox" ? checked : value,
        });
    };

    const togglePasswordVisibility = () => {
        setPasswordShown(!passwordShown);
    };

    const togglePasswordConfirmationVisibility = () => {
        setPasswordConfirmationShown(!passwordConfirmationShown);
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        if (formData.password !== formData.confirmPassword) {
            setError("Passwords do not match.");
            return;
        }

        if (!formData.acceptedTerms) {
            setError("Please accept the terms and conditions.");
            return;
        }


        createUserWithEmailAndPassword(auth, formData.email, formData.password)
            .then((userCredential) => {
                verifyEmail();
                navigate("/userForm");
            })
            .catch((error) => {
                setError(error.message);
            });
    };


    const verifyEmail = () => {
        sendEmailVerification(auth.currentUser)
            .then(() => {
                // Email verification sent!
                // ...

                alert('Please check your Email')
            });
    }

    console.log('This is form data', formData)

    return (
        <Form onSubmit={handleSubmit}>
            <h2>Sign Up</h2>

            {error && <div className="alert alert-danger">{error}</div>}

            <FormGroup>
                <Form.Label for="email">Email</Form.Label>
                <Form.Control
                    type="email"
                    name="email"
                    id="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    required
                />
            </FormGroup>

            <FormGroup>
                <Form.Label for="password">Password</Form.Label>
                <Form.Control
                    type={passwordShown ? "text" : "password"}
                    name="password"
                    id="password"
                    value={formData.password}
                    onChange={handleInputChange}
                />
                <Button onClick={togglePasswordVisibility}>
                    {passwordShown ? "Hide" : "Show"}
                </Button>
            </FormGroup>

            <FormGroup>
                <Form.Label for="confirmPassword">Confirm Password</Form.Label>
                <Form.Control
                    type={passwordConfirmationShown ? "text" : "password"}
                    name="confirmPassword"
                    id="confirmPassword"
                    value={formData.confirmPassword}
                    onChange={handleInputChange}
                />
                <Button onClick={togglePasswordConfirmationVisibility}>
                    {passwordConfirmationShown ? "Hide" : "Show"}
                </Button>
            </FormGroup>

            <FormGroup check>
                <Form.Label check>
                    <Form.Check
                        type="checkbox"
                        name="acceptedTerms"
                        id="acceptedTerms"
                        checked={formData.acceptedTerms}
                        onChange={handleInputChange}
                    />{" "}
                    I accept the terms and conditions
                </Form.Label>
            </FormGroup>

            <Button type="submit">Sign Up</Button>
        </Form>
    );
};

export default SignupForm;
