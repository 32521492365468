import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { userContext } from '../../hooks/UseContextProvider';
import useFirebase from '../../hooks/useFirebase';

const NewUserForm = () => {
    const [name, setName] = useState('');
    const [street, setStreet] = useState('');
    const [city, setCity] = useState('');
    const [country, setCountry] = useState('');
    const [profession, setProfession] = useState('');
    const [degree, setDegree] = useState('');
    const [phone, setPhone] = useState('');
    const [intro, setIntro] = useState('');
    const [skills, setSkills] = useState('');
    const [Interest, setInterest] = useState('');

    const [userProfile, setUserProfile] = useState("");
    const [currentUserId, setCurrentUserId] = useState("");
    const [currenNewtUser, setCurrentUser] = useState("");
    const [profileStatus, setprofileStatus] = useState("");
    const [email, setEmail] = useState("");
    const [university, setUniversity] = useState("");
    const [isButtonEnabled, setIsButtonEnabled] = useState(false);

    const [verifyUserEmail, setVerifyUser] = useState(false);

    const { usersProfile, usersData, currentUser } = useContext(userContext);






    // do something with the form data
    useEffect(() => {
        usersProfile.map((user) => {

            if (usersData.email === user.email) {
                // console.log('I found the user', user)
                setCurrentUserId(user._id);
                setUserProfile(user)
                setIsButtonEnabled(true);
                console.log(user)
            }
            else {
                console.log('Sorry no user found')
            }

        })

    }, [usersProfile, email, usersData.email])

    useEffect(() => {


        if (!currentUserId) {
            setprofileStatus('You need to write only a few more personal information to complete your profile. Please Complete your profile');
            console.log('This is profile status', profileStatus)
        }


    }, [usersData.email, currentUser])

    const handleSubmit = (event) => {

        // console.log('This Is from Handle Submit', name, usersData.email, usersData)

        const Email = usersData.email;

        // console.log('This is users data email', myEmail)
        // const address = { street, city, country }
        const myNewProfile = { name, Email, phone, street, city, country, degree, university, profession, skills, intro }

        console.log('This is new Profile from new form', myNewProfile)
        fetch('https://bd-simulab.onrender.com/userprofiledb', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(myNewProfile)

        })
            .then(response => response.json())
            .then(data => {
                console.log('Response Received', data)


                if (data.insertedId) {
                    alert('Profile Successfully Created')

                    setUserProfile(data)
                    console.log('Submitted', myNewProfile)
                    // console.log(userProfile)

                    setIsButtonEnabled(true);

                    // event.target.reset();
                }


            })
            .catch(error => console.error(error));



        event.target.reset();
        // .catch(error => {
        //     console.error(error);

        // });
        event.preventDefault();
    };

    console.log('This is Current user from New Form', currentUserId)

    return (
        <Container className="mt-5">
            {currentUserId ? (<p>Please go to profile section to edit your info</p>) :
                (
                    <Form onSubmit={handleSubmit}>
                        <Form.Group>
                            <Form.Label>Name*</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter your name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                required
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Mobile Number*</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter your mobile number"
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                                required
                            />
                        </Form.Group>

                        <Row>
                            <Col md={4}>
                                <Form.Group>
                                    <Form.Label>Street*</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter your street"
                                        value={street}
                                        onChange={(e) => setStreet(e.target.value)}
                                        required
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group>
                                    <Form.Label>City*</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter your city"
                                        value={city}
                                        onChange={(e) => setCity(e.target.value)}
                                        required
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Form.Group>
                            <Form.Label>Country*</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter your country"
                                value={country}
                                onChange={(e) => setCountry(e.target.value)}
                                required
                            />
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>Profession</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter your profession"
                                value={profession}
                                onChange={(e) => setProfession(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Skills</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="e.g. Python, SolidWorks etc."
                                value={skills}
                                onChange={(e) => setSkills(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Field of Interest</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="e.g. CFD, FEMA, Robotics etc."
                                value={Interest}
                                onChange={(e) => setInterest(e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>Highest Degree</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter your highest degree"
                                value={degree}
                                onChange={(e) => setDegree(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>University</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter your University Name"
                                value={university}
                                onChange={(e) => setUniversity(e.target.value)}
                            />
                        </Form.Group>



                        <Form.Group>
                            <Form.Label>Short Intro (50 words or less)</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={2}
                                maxLength={200}
                                placeholder="Enter a short intro"
                                value={intro}
                                onChange={(e) => setIntro(e.target.value)}
                            />
                        </Form.Group>

                        <Button className='m-2' variant="primary" type="submit">
                            Create Profile
                        </Button>
                        <Button variant="warning" className="m-2 p-2" disabled={!isButtonEnabled} > <Link to={`/updateuser/${currentUser._id}`} style={{ color: 'Black', fontWeight: 'bold', textDecoration: 'none' }} >Edit Profile</Link></Button>
                    </Form>
                )

            }
        </Container>
    );
};

export default NewUserForm;
