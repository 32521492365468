import React, { useState } from 'react';
import axios from 'axios';
import * as XLSX from 'xlsx';

const ExportToExcel = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    const [totalStudents, setTotalStudents] = useState('')

    const fetchData = async () => {
        try {
            setLoading(true);
            const response = await axios.get('https://bd-SIMULAB.onrender.com/enrollrequestdb');
            setData(response.data);
            setTotalStudents(data.length);
            console.log(response.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    const exportToExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
        XLSX.writeFile(workbook, 'exported_data.xlsx');
    };

    return (
        <div>
            <button onClick={fetchData} disabled={loading}>
                {loading ? 'Loading...' : 'Fetch Data'}
            </button>
            <button onClick={exportToExcel} disabled={data.length === 0}>
                Export to Excel
            </button>
            <p style={{ color: 'white' }}>Total Enrolled: {data.length}</p>
            <div style={{ overflowY: 'auto', maxHeight: '400px', marginTop: '20px' }}>
                <table style={{ width: '100%', borderCollapse: 'collapse', backgroundColor: 'white' }}>
                    <thead style={{ backgroundColor: '#333', color: 'white' }}>
                        <tr>
                            <th style={tableHeaderStyle}>Full Name</th>
                            <th style={tableHeaderStyle}>Email</th>
                            <th style={tableHeaderStyle}>Mobile Number</th>
                            {/* Add more headers based on your API response */}
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((item) => (
                            <tr key={item._id} style={tableRowStyle}>
                                <td>{item.FullName}</td>
                                <td>{item.Email}</td>
                                <td>{item.MobileNumber}</td>
                                {/* Add more cells based on your API response */}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

const tableHeaderStyle = {
    position: 'sticky',
    top: '0',
    padding: '15px',
    textAlign: 'left',
    backgroundColor: '#333',
    color: 'white',
    borderBottom: '2px solid #ddd',
    zIndex: '1',
};

const tableRowStyle = {
    border: '1px solid #ddd',
};

export default ExportToExcel;
