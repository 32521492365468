import React, { useState } from 'react';
import { Container, Row, Col, Button, Card, Carousel } from 'react-bootstrap';
import './Events.css'

const Events = () => {
    const [eventCard, setEventCard] = useState(null);

    const cardData = [
        {
            id: 1,
            title: 'Introduction to Computational Fluid Dynamics (CFD): Fundamentals, Applications, and Simulation Techniques',
            description: 'This seminar aims to provide participants with a beginner guide guide to CFD, covering the fundamentals, Applications, numerical methods, and practical techniques used in CFD. Please register with some basic information.',
            banner: 'https://lh3.googleusercontent.com/6xX3iy5CEvNvJ5OpHflk_iRA3ASuVRUyzGaMIbyHmDTAC_yO4Jp_j1bxaWpif_A47IqZfMaQN-tFMU70ipbg9nDW6asOUGvjzrPWJiwV9Lwr42ODmdBYLu4VZ6LE8R8U7-FhMkDRTCbEXXuBunXeYNfG0kDq_CfF3wk04loessrXQ-AT6NdaB4-cwN5zvi6GEeplGcCuYArObFVNQblEdFXZXHQzLJK2Z6IkHVInn6r3tQ6D0cIlS86uF3A2040hgQhAuntU3z6ygZVpsHxoRcu7IadoE1rau4DbME_NFoXMDtHh-qN0whKu5muXown3Uv8gcJI1vYHveCNcUXwpkxOBh86v1cwDFIoZHDk3HaVA_EBKCCLg65J1Lia6THBW-rInzPlV_SdWOUkyZtcMQrFVCoGnNZ2Ng1v4q8dCVgJB-ZUpouTQV-2CRXml7HNku1y6kD879Ps7oe0cKHsT4Csj-F6u8GrsVi7bnnwl7nKDscPQmxk6e7ChngoT4S2tiKHEHZZzeWTEy5uiMDEb6jj3WUvUA9vhCja7C-SCnRYrrzuAaMXVL_NC9IBmPK5yaVV7iSoYw95Ju-oqpUt9qLcgF5jU6Tp-Riu-DSFbo7lpx0s8Nhdua5FMB4rUFUnQuJEEXclWSjHAu4unTN70DKvm8WBvWoLxSsFuRUGZy4sWZ2qMLIOVP9o1tw1E8Iawua2BNCnNm4--XjRF0d70pz3mqfrkE9goex55lLdvpePHgLbVPLyzfDZ_QzGmcoMsmKxZWQ21SCAlhfxPte97wuTWmZ33vPxOzTnF1_DSjQXGwFQkENwY5mL8puB7lCU_xSMi97xbUPRvN4jInQQhNVyTSaskHgh9EZofBuiVsxrWkrPHA-Hh4cjySuTf5W0IqaFmGF3VkOOO1lWZcv0Yg5cRUlw1elkFBEYzsJ1VhwY831s8=w1805-h903-s-no?authuser=0',
            details: 'Coming soon.......'
        },
        {
            id: 2,
            title: 'CFD for Building Technology',
            description: 'This seminar aims to provide participants with a beginner guide guide to CFD, covering the fundamentals, Applications, numerical methods, and practical techniques used in CFD. Please register with some basic information.',
            banner: 'https://lh3.googleusercontent.com/6xX3iy5CEvNvJ5OpHflk_iRA3ASuVRUyzGaMIbyHmDTAC_yO4Jp_j1bxaWpif_A47IqZfMaQN-tFMU70ipbg9nDW6asOUGvjzrPWJiwV9Lwr42ODmdBYLu4VZ6LE8R8U7-FhMkDRTCbEXXuBunXeYNfG0kDq_CfF3wk04loessrXQ-AT6NdaB4-cwN5zvi6GEeplGcCuYArObFVNQblEdFXZXHQzLJK2Z6IkHVInn6r3tQ6D0cIlS86uF3A2040hgQhAuntU3z6ygZVpsHxoRcu7IadoE1rau4DbME_NFoXMDtHh-qN0whKu5muXown3Uv8gcJI1vYHveCNcUXwpkxOBh86v1cwDFIoZHDk3HaVA_EBKCCLg65J1Lia6THBW-rInzPlV_SdWOUkyZtcMQrFVCoGnNZ2Ng1v4q8dCVgJB-ZUpouTQV-2CRXml7HNku1y6kD879Ps7oe0cKHsT4Csj-F6u8GrsVi7bnnwl7nKDscPQmxk6e7ChngoT4S2tiKHEHZZzeWTEy5uiMDEb6jj3WUvUA9vhCja7C-SCnRYrrzuAaMXVL_NC9IBmPK5yaVV7iSoYw95Ju-oqpUt9qLcgF5jU6Tp-Riu-DSFbo7lpx0s8Nhdua5FMB4rUFUnQuJEEXclWSjHAu4unTN70DKvm8WBvWoLxSsFuRUGZy4sWZ2qMLIOVP9o1tw1E8Iawua2BNCnNm4--XjRF0d70pz3mqfrkE9goex55lLdvpePHgLbVPLyzfDZ_QzGmcoMsmKxZWQ21SCAlhfxPte97wuTWmZ33vPxOzTnF1_DSjQXGwFQkENwY5mL8puB7lCU_xSMi97xbUPRvN4jInQQhNVyTSaskHgh9EZofBuiVsxrWkrPHA-Hh4cjySuTf5W0IqaFmGF3VkOOO1lWZcv0Yg5cRUlw1elkFBEYzsJ1VhwY831s8=w1805-h903-s-no?authuser=0',
            details: 'Coming soon.......'
        },
    ];

    const handleEventCard = (card) => {
        if (eventCard !== null) {
            setEventCard(null)
        }
        else {
            setEventCard(card);

        }
        console.log('This is even card', eventCard)
    };

    return (
        <Container>
            <Row className="event-all">
                <Col lg={6} md={6} s={12}>
                    <iframe
                        src="https://docs.google.com/forms/d/e/1FAIpQLSfWZQzy0QCot8ft43K4YuWtDdjgwHo109iZ0y57WzMKkylCzA/viewform?embedded=true"
                        width="100%"
                        height="500"
                        frameborder="0"
                        marginheight="0"
                        marginwidth="0"
                    >
                        Loading...
                    </iframe>
                </Col>
                <Col s={12} lg={4}>
                    <Carousel className='multi-carousel' touch={true} interval={null} slide={true}>
                        {cardData.map((card) => (
                            <Carousel.Item key={card.id}>
                                <Card className='multi-carousel-card'>
                                    <Card.Img variant='top' src={card.banner} />
                                    <Card.Body className='card-body'>
                                        <Card.Title maxlength="10">{card.title}</Card.Title>
                                        <Card.Text>{card.description}</Card.Text>
                                        <Button variant='outline-primary' onClick={() => handleEventCard(card)}>
                                            <a href="#event-details" style={{ textDecoration: 'none', colo: 'black' }}> See more</a>
                                        </Button>
                                    </Card.Body>
                                </Card>
                            </Carousel.Item>
                        ))}
                    </Carousel>
                </Col>
            </Row>

            {eventCard && (
                <Row className='event-details' >
                    <Col>
                        <Card>
                            <Card.Img variant='top' src={eventCard.banner} />
                            <Card.Body id='event-details'>
                                <Card.Title>{eventCard.title}</Card.Title>
                                <Card.Text>{eventCard.details}</Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            )}
        </Container>
    );
};

export default Events;
