import React, { useContext, useState } from 'react';
import { Form, Button, Alert, Container, Col, Row } from 'react-bootstrap';
// import { FontAwesomeIcon } from '@fontawesome/react-fontawesome';
import { FaEye, FaEyeSlash, FaGoogle } from 'react-icons/fa';
// import { getAut } from '../hooks/useAuth';
import { getAuth, GoogleAuthProvider, ProviderId, signInWithEmailAndPassword, signInWithPopup } from 'firebase/auth';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import initializeAuthentication from '../hooks/firebase.init';



import './SignIn.css';
import { userContext } from '../hooks/UseContextProvider';
import { useEffect } from 'react';

initializeAuthentication();
const provider = new GoogleAuthProvider();

const SignIn = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [user, setUser] = useState({})
    const [error, setError] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const { login, loginWithGoogle, resetPassword } = getAuth();

    const [userEmail, setUserEmail] = useState('');

    // const { firebaseData } = useContext(useContext);
    const { firebaseData } = useContext(userContext);






    useEffect(() => {
        setUserEmail(firebaseData.user.email);

        // console.log('USER Email Found', userEmail);

    }, [firebaseData])

    const navigate = useNavigate();
    const location = useLocation();

    const auth = getAuth();
    const redirect_url = location.state?.from || '/home'

    const handleSubmit = (e) => {
        e.preventDefault();

        signInWithEmailAndPassword(auth, email, password)
            .then(result => {
                // Signed in 
                const user = result.user;
                navigate(redirect_url)
                console.log(user)
            })
            .catch(error => {
                setError(error.message)
            })




        console.log('clicked')

    };

    const handleGoogleSignIn = () => {

        signInWithPopup(auth, provider)
            .then((result) => {
                // This gives you a Google Access Token. You can use it to access the Google API.
                const credential = GoogleAuthProvider.credentialFromResult(result);

                // The signed-in user info.
                const { displayName, email, photoURL } = result.user;
                // ...
                const logeInUser = {
                    name: displayName,
                    email: email,
                    photo: photoURL

                };

                setUser(logeInUser);
                navigate(redirect_url)

            })

            .catch(error => {
                console.log(error.message)
            })


    }

    const handleResetPassword = () => {
        resetPassword(email)
            .then(() => setError('Password reset email sent!'))
            .catch((error) => setError(error.message));
    };




    return (
        <Container fluid className='signin-Container' >
            <Col>
                <Row className='signin-form' >

                    <Col lg={3} className='signin-box' >

                        {error && <Alert variant="danger">{error}</Alert>}
                        {userEmail ? <Container>
                            <p style={{ color: 'orange' }}>You are already Signend In Please go back to Home</p>
                        </Container> :

                            <Container fluid>
                                <Row>
                                    <h4 style={{ color: 'orange' }}>Welcome Back</h4>
                                    <p>Please SignIn</p>
                                </Row>
                                <Form onSubmit={handleSubmit}>
                                    <Form.Group controlId="formBasicEmail">
                                        <Form.Label>Email address</Form.Label>
                                        <Form.Control
                                            type="email"
                                            placeholder="Enter email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                    </Form.Group>

                                    <Form.Group controlId="formBasicPassword">
                                        <Form.Label>Password</Form.Label>
                                        <Form.Control
                                            type={showPassword ? 'text' : 'password'}
                                            placeholder="Password"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                        />
                                        {showPassword ? (
                                            <FaEyeSlash
                                                className="password-icon"
                                                onClick={() => setShowPassword(false)}
                                            />
                                        ) : (
                                            <FaEye
                                                className="password-icon"
                                                onClick={() => setShowPassword(true)}
                                            />
                                        )}
                                    </Form.Group>

                                    <Button variant="outline-warning" type="submit">
                                        Login
                                    </Button>

                                    <Button
                                        variant="outline-warning"
                                        onClick={handleGoogleSignIn}
                                        className="google-login-btn m-3"
                                    >
                                        <FaGoogle />
                                        &nbsp; Login with Google
                                    </Button>

                                    <Link to='/forgetpassword'>

                                        <Button variant="outline-success" style={{ color: 'white' }}>
                                            Forgot Password?
                                        </Button>
                                    </Link>


                                    <Link to='/signup'>  <Button className="m-1" variant="outline-success" style={{ color: 'white' }} onClick={handleResetPassword}>
                                        New? SignUp here
                                    </Button></Link>


                                </Form>

                            </Container>
                        }






                    </Col>
                </Row>

            </Col>

        </Container>
    );
};

export default SignIn;
