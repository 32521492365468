

import Carousel from 'react-bootstrap/Carousel';
import bannerimg1 from '../../Utilities/Images/WelComeBanner.gif'
import bannerimg2 from '../../Utilities/Images/Banner2-img.png'

import './Baner.css'

import React from 'react';
import { Button } from 'bootstrap';
import { Container, Image } from 'react-bootstrap';


const Banner = () => {
    return (

        
            <Carousel variant="dark">
                <Carousel.Item>
                    <Image
                        fluid
                        className="d-block w-100 h-50"
                        src={bannerimg1}
                        alt="First slide"
                        style={{ border: '2px solid green' }}
                    />

                </Carousel.Item>
                {/* <Carousel.Item>
                    <Image
                        fluid
                        className="d-block w-100 h-50"
                        src={bannerimg2}
                        alt="First slide"
                    // style={{ height: '20rem'}}
                    />

                </Carousel.Item> */}

            </Carousel>
        

    );
};

export default Banner;