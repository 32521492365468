import React from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import './About.css';

import logo from '../../Utilities/Images/bdsimlab3.png';
import aboutbanner from '../../Utilities/Images/WelComeBanner.gif';
import { Link } from 'react-router-dom';
import { FaFacebook, FaLinkedin } from 'react-icons/fa'

const About = () => {
    return (
        <Container fluid >
            <Row className="about-container">

                <Col lg={12} fluid className='banner-img'>
                    <Image fluid src={aboutbanner} alt="" />
                </Col>

                <Col lg={12} >
                    <Row>
                        <Col lg={3} md={3} className="about-logo">
                            Follow us on
                            <br />

                            {/* <Link to="/home">Homes</Link> */}
                            <a href="https://www.facebook.com/profile.php?id=100090365978893" target="_blank" rel="noopener noreferrer">
                                <FaFacebook></FaFacebook> Facebook
                            </a>

                            <a href="https://www.linkedin.com/groups/14206681/" target="_blank" rel="noopener noreferrer">
                                <FaLinkedin></FaLinkedin> LinkedIn
                            </a>

                            <br />
                            <br />
                            <p>Look for latest events <Link to='/' style={{ color: 'orange' }}>here</Link></p>



                        </Col>
                        <Col lg={6} md={6} className='details'>
                            <h1 className="About-header"> <span style={{ color: 'green' }}>BD</span> <span style={{ color: 'orange' }}>- SimuLab</span></h1>
                            <p >
                                BD-SimuLab is an esteemed engineering computation center that specializes in providing mechanical simulation services for product and process development. Our expertise lies in the field of 3D Computational Fluid Dynamics (CFD) for thermal, fluid, and Heat transfer analysis, CFD for Building Technology or HVAC, system design for Tharmal and Hydraulic system. BD-SimuLab aims to bridge the gap between academia and industry by equipping young professionals, engineering students, and businesses with the necessary skills and knowledge to excel in their product development endeavors.
                            </p>


                            <p>To achieve their mission, BD-SimuLab offers comprehensive training workshops, webinars, and consultations. These programs are designed to empower individuals and businesses to meet the challenges of the modern workplace. The training program encompasses various software platforms, including Solidworks Simulation, ANSYS Fluent, Autodesk CFD, and MATLAB Simulink/Simscap. By providing access to these industry-standard tools, BD-SimuLab enables participants to gain hands-on experience and develop the confidence required to accomplish their career objectives.</p>

                            <p>The founder of BD-SimuLab is Sadeque Hossain, an Engineering computation and Simulation enthusiast. He was born in Chattagram, Bangladesh, and holds a Bachelor's degree in Industrial and Production Engineering from Dhaka University of Engineering and Technology (DUET), Gazipur. Subsequently, he pursued a Master's degree in Power Engineering from Brandenburg University of Technology, Germany.</p>

                            <p>With several years of experience in the field of Mechanical Design and Simulation, Sadeque Hossain possesses a keen interest in CFD, fluid dynamics, heat transfer, system design for fluid and hydraulic systems, as well as CFD applications in building technology. Currently, he serves as a Planning and Simulation Engineer at IMF GmbH, Germany, and also works as an energy consultant at Northvolt3, Germany.</p>
                        </Col>
                        <Col lg={3} className="founder-details">
                            <div><h4>Sadeque Hossain</h4>

                                <p>B.Sc. industrial and Production Engineering
                                    from Dhaka university of Engineering and Technology (DUET), Bangladesh; <br />
                                    M.Sc. Power Engineering from
                                    Brandenburg University of Technology, Germany;</p>
                                <p style={{ color: 'orange' }}>Founder BD-SimuLab</p>
                                <p>Planning and Simulation Engineer, IMF GmbH, Germany;</p>
                                <p>CFD for Building Technology || HVAC || Thermal and Hydraulic System Design</p>



                            </div>
                        </Col>
                    </Row>

                </Col>

            </Row>
        </Container>
    );
};

export default About;